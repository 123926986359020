<template>
  <div class="content">
    <div class="head">
      <div class="head_item">
        <p class="head_title">教练数</p>
        <p class="head_data" style="color: #67c23a">{{ coachcount }}</p>
      </div>
      <div class="head_item">
        <p class="head_title">课程数</p>
        <p class="head_data" style="color: #409eff">{{ classcount }}</p>
      </div>
      <div class="head_item">
        <p class="head_title">学员数</p>
        <p class="head_data" style="color: #e6a23c">{{ membercount }}</p>
      </div>
    </div>
    <div class="main">
      <div class="match_item">
        <div class="match_top">
          <p>场地LOGO(用于标识地图上的场地位置)</p>
          <p @click="editVenue"><v-icon class="back">mdi-pencil</v-icon>编辑</p>
        </div>
        <div class="match_bottom">
          <upload
            :img="venue.logo"
            :close="true"
            :confirm="true"
            :confirmText="'删除LOGO'"
            @removefinish="removeLogo"
            @uploadfinish="addLogo"
            style="padding: 0; width: 200px; height: 180px"
            :accept="'image/jpeg,image/png'"
          />
        </div>
      </div>
      <div class="match_item">
        <p>宣传图（建议尺寸800*400，最多可上传3张）</p>
        <div style="display: flex">
          <upload
            style="padding: 0; width: 33%; height: 200px; margin-right: 20px"
            :img="venue.images[0]"
            :close="true"
            :confirm="true"
            :confirmText="'删除此宣传图'"
            @removefinish="removeSlider1"
            @uploadfinish="addSlider1"
            :accept="'image/jpeg,image/png'"
          />
          <upload
            style="padding: 0; width: 33%; height: 200px; margin-right: 20px"
            :img="venue.images[1]"
            :close="true"
            :confirm="true"
            :confirmText="'删除此宣传图'"
            @removefinish="removeSlider2"
            @uploadfinish="addSlider2"
            :accept="'image/jpeg,image/png'"
          />
          <upload
            style="padding: 0; width: 33%; height: 200px; margin-right: 20px"
            :img="venue.images[2]"
            :close="true"
            :confirm="true"
            :confirmText="'删除此宣传图'"
            @removefinish="removeSlider3"
            @uploadfinish="addSlider3"
            :accept="'image/jpeg,image/png'"
          />
        </div>
      </div>
      <div class="match_item">
        <p>营业时间</p>
        <span>
          <img
            style="
              display: inline-block;
              width: 30px;
              height: 30px;
              vertical-align: middle;
            "
            src="https://scdn.xidong360.com/zzty/admin/img/time.png"
            alt
          />
          <span style="margin: 5px 10px; display: inline-block">
            营业时间：{{ venue.time }}
          </span>
        </span>
      </div>
      <div class="match_item">
        <div style="display: flex; margin-bottom: 10px">
          <v-icon style="font-size: 28px" small>mdi-map-marker</v-icon>
          <v-row v-if="addressChanged" style="margin: 0; padding: 0">
            <v-col cols="6" style="margin: 0; padding: 0">
              <v-text-field
                v-model="venuelocation.address"
                placeholder="地点"
                dense
                hide-details
              />
            </v-col>
            <v-col
              cols="6"
              style="margin: 0; padding: 0; display: flex; align-items: center"
            >
              <span
                v-if="addressChanged"
                @click="saveAddress"
                style="
                  color: #3c8dbc;
                  margin-left: 5px;
                  cursor: pointer;
                  font-size: 18px;
                "
              >
                保存
              </span>
              <span
                v-if="addressChanged"
                @click="addressChanged = false"
                style="
                  color: #ff5252;
                  margin-left: 5px;
                  cursor: pointer;
                  font-size: 18px;
                "
              >
                放弃
              </span>
            </v-col>
          </v-row>
          <span v-else style="font-size: 18px">地点：{{ venue.address }}</span>
        </div>
        <maps
          v-if="fetched"
          id="venueBasicinfoMap"
          @setlocation="setlocation"
          :location="{
            address: venue.address,
            latitude: venue.latitude,
            longitude: venue.longitude,
          }"
        />
      </div>
      <div class="match_item">
        <p>负责人</p>
        <span>
          <v-icon small>mdi-account</v-icon>
          负责人姓名：{{ venue.linkman }}
        </span>
      </div>
      <div class="match_item">
        <p>联系方式</p>
        <span>
          <v-icon small>mdi-phone</v-icon>
          联系电话：{{ venue.linkphone }}
        </span>
      </div>
      <div class="match_item">
        <p>场地简介</p>
        <span v-html="venue.desc"></span>
      </div>
    </div>
    <v-dialog v-model="showEdit" max-width="800px" persistent>
      <v-card>
        <v-card-title style="display: flex; justify-content: space-between">
          <span class="headline">场地编辑</span>
          <v-icon @click="showEdit = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="名称"
                  placeholder="请输入场地名称"
                  v-model="venue_.name"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  :items="tags"
                  v-model="venue.tags"
                  item-text="name"
                  item-value="name"
                  label="场地类别"
                  multiple
                >
                  <template v-slot:item="{ item }">
                    <v-list-item
                      @click.stop="clickTag(item.name)"
                      :class="
                        item.selected ? 'primary--text v-list-item--active' : ''
                      "
                    >
                      <v-list-item-action>
                        <v-checkbox v-model="item.selected"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-avatar>
                        <v-img :src="item.cover" />
                      </v-list-item-avatar>
                      <v-list-item-content style="padding-left: 10px">
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="负责人"
                  placeholder="请输入负责人姓名"
                  v-model="venue_.linkman"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="联系电话"
                  placeholder="请输入联系电话"
                  v-model="venue_.linkphone"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="场地简介"
                  v-model="venue_.desc"
                  outlined
                  rows="4"
                  row-height="25"
                  shaped
                  dense
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showEdit = false">取消</v-btn>
          <v-btn color="blue darken-1" dark @click="saveVenue">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "underscore";
import Vue from "vue";
import upload from "@/components/upload";
import maps from "@/components/map";
import { Picker } from "vant";
Vue.use(Picker);
export default {
  name: "VenueBasicInfo",
  data() {
    return {
      tags: [],
      coachcount: "-",
      classcount: "-",
      membercount: "-",
      venue: {
        images: ["", "", ""],
      },
      fetched: false,
      venuelocation: {},
      addressChanged: false,
      showEdit: false,
      venue_: {},
    };
  },
  watch: {},
  computed: {},
  methods: {
    gettags() {
      // this.$api
      //   .ssyvenueGettags()
      //   .then((res) => {
      //     if (res.code === 0) {
      //       this.tags = res.data.tags;
      //     }
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //   });
    },
    clickTag(tagname) {
      console.log(tagname);
      let tindex = this.venue_.tags.indexOf(tagname);
      if (tindex === -1) this.venue_.tags.push(tagname);
      else this.venue_.tags.splice(tindex, 1);
      this.tags.forEach((tag) => {
        if (tag.name === tagname) tag.selected = tindex === -1;
      });
    },
    getdata() {
      // this.$toast.loading({ message: "加载数据" });
      // this.$api
      //   .venueBasicinfo({ venueId: this.$route.query.id })
      //   .then((res) => {
      //     this.$toast.clear();
      //     if (res.code === 0) {
      //       this.coachcount = res.data.coachcount;
      //       this.classcount = res.data.classcount;
      //       this.membercount = res.data.membercount;
      //       this.venue = res.data.venue;
      //       if (res.data.venue) {
      //         this.$store.dispatch("SetNavTitle", res.data.venue.name);
      //         this.$store.dispatch("SetNavLogo", res.data.venue.logo);
      //       }
      //       this.fetched = true;
      //       this.addressChanged = false;
      //     } else {
      //       this.$dialog.alert({ title: "提示", message: res.msg });
      //     }
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //     this.$toast.clear();
      //   });
    },
    editMatch() {},
    removeLogo(url) {
      if (!url) return;
      // this.$toast.loading({ message: "" });
      // this.$api
      //   .venueRemovelogo({ venueId: this.$route.query.id })
      //   .then((res) => {
      //     this.$toast.clear();
      //     if (res.code === 0) {
      //       this.getdata();
      //     } else {
      //       this.$dialog.alert({ title: "提示", message: res.msg });
      //     }
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //     this.$toast.clear();
      //   });
    },
    addLogo(url) {
      if (!url) return;
      // this.$toast.loading({ message: "" });
      // this.$api
      //   .venueAddlogo({ venueId: this.$route.query.id, url })
      //   .then((res) => {
      //     // console.log(res);
      //     this.$toast.clear();
      //     if (res.code === 0) {
      //       this.getdata();
      //     } else {
      //       this.$dialog.alert({ title: "提示", message: res.msg });
      //     }
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //     this.$toast.clear();
      //   });
    },
    addSlider1(url) {
      this.addSlider(url, 0, (result) => {
        if (result.code === 0) {
          this.getdata();
        }
      });
    },
    addSlider2(url) {
      this.addSlider(url, 1, (result) => {
        if (result.code === 0) {
          this.getdata();
        }
      });
    },
    addSlider3(url) {
      this.addSlider(url, 2, (result) => {
        if (result.code === 0) {
          this.getdata();
        }
      });
    },
    addSlider(url, index, callback) {
      if (!url) return callback({ code: 1 });
      // this.$toast.loading({ message: "" });
      // this.$api
      //   .venueAddslider({ venueId: this.$route.query.id, url, index })
      //   .then((res) => {
      //     // console.log(res);
      //     this.$toast.clear();
      //     if (res.code === 0) {
      //       callback({ code: 0 });
      //     } else {
      //       this.$dialog.alert({ title: "提示", message: res.msg });
      //       callback({ code: 1 });
      //     }
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //     this.$toast.clear();
      //     callback({ code: 1 });
      //   });
    },
    removeSlider1(url) {
      this.removeSlider(url, (result) => {
        if (result.code === 0) {
          this.getdata();
        }
      });
    },
    removeSlider2(url) {
      this.removeSlider(url, (result) => {
        if (result.code === 0) {
          this.getdata();
        }
      });
    },
    removeSlider3(url) {
      this.removeSlider(url, (result) => {
        if (result.code === 0) {
          this.getdata();
        }
      });
    },
    removeSlider(url, callback) {
      if (!url) return { code: 1 };
      // this.$toast.loading({ message: "" });
      // this.$api
      //   .venueRemoveslider({ venueId: this.$route.query.id, url })
      //   .then((res) => {
      //     // console.log(res);
      //     this.$toast.clear();
      //     if (res.code === 0) {
      //       callback({ code: 0 });
      //     } else {
      //       this.$dialog.alert({ title: "提示", message: res.msg });
      //       callback({ code: 1 });
      //     }
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //     this.$toast.clear();
      //     callback({ code: 1 });
      //   });
    },
    setlocation(location) {
      this.venuelocation = location;
      this.addressChanged = true;
    },
    saveAddress() {
      // this.$toast.loading({ message: "" });
      // this.$api
      //   .venueSaveaddress({
      //     venueId: this.$route.query.id,
      //     ..._.pick(this.venuelocation, ["address", "latitude", "longitude"]),
      //   })
      //   .then((res) => {
      //     // console.log(res);
      //     this.$toast.clear();
      //     if (res.code === 0) {
      //       this.getdata();
      //     } else {
      //       this.$dialog.alert({ title: "提示", message: res.msg });
      //     }
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //     this.$toast.clear();
      //   });
    },
    editVenue() {
      this.setVenue();
      this.showEdit = true;
    },
    setVenue() {
      this.venue_.name = this.venue.name;
      this.venue_.tags = this.venue.tags;
      this.tags.forEach((tag) => {
        tag.selected = this.venue.tags.indexOf(tag.name) > -1;
      });
      this.venue_.linkman = this.venue.linkman;
      this.venue_.linkphone = this.venue.linkphone;
      this.venue_.desc = this.venue.desc;
      this.venue_.info = this.venue.info;
    },
    saveVenue() {
      // this.$toast.loading({ message: "" });
      // this.$api
      //   .ssyvenueSave({
      //     venueId: this.$route.query.id,
      //     venueinfo: {
      //       ...this.venue_,
      //       opendays: this.opendays,
      //     },
      //   })
      //   .then((res) => {
      //     // console.log(res);
      //     this.$toast.clear();
      //     if (res.code === 0) {
      //       this.showEdit = false;
      //       this.getdata();
      //     } else {
      //       this.$dialog.alert({ title: "提示", message: res.msg });
      //     }
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //     this.$toast.clear();
      //   });
    },
  },
  created() {},
  activated() {
    this.gettags();
    this.getdata();
  },
  components: {
    upload,
    maps,
  },
};
</script>
<style lang='less' scoped>
.content {
  padding: 0 20px 0;
  .head {
    display: flex;
    border-bottom: 1px solid #eee;
    padding: 10px;
    .head_item {
      flex: 1;
      border-right: 1px solid #eee;
      p {
        text-align: center;
        color: #999;
        font-size: 18px;
        margin-bottom: 0px;
      }
      p.head_data {
        font-size: 30px;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
  .main {
    margin-top: 10px;
    margin-bottom: 100px;
    .match_item {
      margin-bottom: 10px;
      font-size: 16px;
      color: #444;
      border-bottom: 1px solid #eee;
      padding-bottom: 10px;
      p {
        padding-bottom: 10px;
        margin-bottom: 0;
      }
      span {
        font-size: 14px;
        color: #5e6166;
        display: block;
      }
      .match_top {
        display: flex;
        justify-content: space-between;
        .back {
          margin-right: 5px;
          color: #3c8dbc;
          padding-bottom: -10px;
          font-size: 16px;
        }
        p {
          &:last-child {
            color: #3c8dbc;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
      #upload {
        padding: 0;
        width: 150px;
        height: 140px;
      }
    }
  }
}
#venueBasicinfoMap {
  width: 100%;
  height: 500px;
}
</style>
