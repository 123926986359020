<template>
  <div>
    <quill-editor
      @change="onEditorBlur($event)"
      id="qcontent"
      ref="quill"
      v-model="qcontent"
      :options="editorOption"
    ></quill-editor>
    <input
      ref="upload"
      type="file"
      @change="change"
      id="qupload"
      accept="image/jpeg, image/png"
      style="display: none"
    />
  </div>
</template>

<script>
import { quillEditor, Quill } from "vue-quill-editor";
import { container, ImageExtend, QuillWatch } from "quill-image-extend-module";
import QuillHtmlSourceButton from "quill-source-code-button";
Quill.register("modules/ImageExtend", ImageExtend);
Quill.register("modules/htmlSource", QuillHtmlSourceButton);
export default {
  name: "quill",
  props: ["content", "placeholder"],
  data() {
    return {
      editorOption: {
        placeholder: this.placeholder,
        modules: {
          htmlSource: {},
          ImageExtend: {
            loading: true,
            name: "img",
            // action: UploadImage, //上传的服务器地址
            response: (res) => {
              return res.data;
            },
          },
          toolbar: {
            container: container,
            handlers: {
              image: function (value) {
                if (value) {
                  document.querySelector("#qupload").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
            theme: "snow",
          },
        },
      },
      qcontent: "",
    };
  },
  watch: {
    content(newContent, oldContent) {
      this.qcontent = newContent;
    },
  },
  methods: {
    setcontent(data) {
      this.qcontent = data;
    },
    change(e) {
      let file = e.target.files[0];
      console.log(file);
      if (!file) return;
      this.$refs.upload.value = "";
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG && !isPNG) {
        return this.showwarntext("上传的图片只能是 JPG 和 PNG 格式!");
      } else if (!isLt2M) {
        return this.showwarntext("上传的图片最大不能超过2MB!");
      }
      let formData = new FormData();
      formData.append("file", file);
      this.$toast.loading({ message: "上传中..." });
      this.$api
        .upload(formData)
        .then((res) => {
          // console.log(res);
          this.$toast.clear();
          if (res.code === 0) {
            let quill = this.$refs.quill.quill;
            let length = quill.getSelection().index
              ? quill.getSelection().index
              : quill.selection.savedRange.index;
            quill.insertEmbed(length, "image", res.data);
            quill.setSelection(length + 1);
          } else {
            this.showwarntext(res.msg);
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
          this.showwarntext("上传失败");
        });
    },
    showwarntext(text) {
      this.$dialog.alert({ title: "提示", message: text });
    },
    onEditorBlur(e) {
      this.$emit("send", this.qcontent);
    },
  },
  created() {
    this.qcontent = this.content;
  },
  activated() {},
};
</script>
<style scoped>
.quill-editor {
  height: 300px;
  margin-bottom: 90px;
}
</style>
