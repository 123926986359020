<template>
  <div class="content">
    <v-btn color="primary" style="margin-bottom: 20px" @click="showedit">
      <v-icon dark>mdi-plus</v-icon>新增分类
    </v-btn>
    <van-empty v-if="tags.length === 0" description="暂无数据" />
    <v-data-table
      v-else
      :headers="headers"
      :items="tags"
      :items-per-page="tags.length"
      disable-sort
      class="elevation-1"
      hide-default-footer
    >
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAt | dateTime }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn text small color="primary" @click="showedit(item)">编辑</v-btn>
        <!-- <v-btn text small color="error" @click="removetag(item._id)">
          删除
        </v-btn> -->
      </template>
    </v-data-table>

    <div class="form">
      <v-row justify="center">
        <v-dialog v-model="showModel" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">新增分类</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="分类名称 *"
                      v-model="tag.name"
                      :rules="[rules.required]"
                      required
                    />
                  </v-col>
                </v-row>
              </v-container>
              <small>* 为必填项</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="showModel = false">
                关闭
              </v-btn>
              <v-btn color="primary" @click="savetag">保存</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "NewsTags",
  data() {
    return {
      headers: [
        { text: "名称", value: "name" },
        { text: "创建时间", value: "createdAt" },
        { text: "操作", value: "action", align: "center", width: 190 },
      ],
      tags: [],
      tag: { _id: "", name: "", icon: "" },
      showModel: false,
      rules: {
        required: (val) => !!val || "必填项",
      },
    };
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "加载数据" });
      this.$api
        .doAPI({ Action: "NewsTags" })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.tags = res.data.tags;
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    checktag() {
      if (!this.tag.name) return "名称不能为空";
    },
    savetag() {
      let checkresult = this.checktag();
      if (checkresult)
        return this.$dialog.alert({ title: "提示", message: checkresult });
      this.$toast.loading({ message: "加载数据" });
      this.$api
        .doAPI({ Action: "SaveNewsTag", Data: { taginfo: this.tag } })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.getdata();
            this.showModel = false;
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    showedit(tag) {
      this.tag = tag || {
        _id: "",
        name: "",
        icon: "",
      };
      this.showModel = true;
    },
    setIcon(icon) {
      this.tag.icon = icon;
    },
  },
  created() {},
  activated() {
    this.getdata();
  },
  components: {},
  watch: {},
};
</script>
<style scoped>
.v-data-table {
  width: 100%;
}
input[type="file"] {
  position: absolute;
  left: -99999px;
}
#upload {
  width: 350px;
  height: 200px;
}
.content {
  padding: 20px 10px 54px;
}
.item {
  height: 100px;
}
</style>