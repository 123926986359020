<template>
  <div class="content">
    <v-btn color="primary" style="margin-bottom: 20px" @click="showadd">
      <v-icon dark>mdi-plus</v-icon>新增账户
    </v-btn>
    <van-empty v-if="list.length === 0" description="暂无数据" />
    <v-data-table
      v-else
      :headers="headers"
      :items="list"
      :items-per-page="list.length"
      disable-sort
      class="elevation-1"
      hide-default-footer
    >
      <template v-slot:[`item.avatar`]="{ item }">
        <v-avatar style="width: 30px; height: 30px; min-height: 30px">
          <img :src="item.avatar" />
        </v-avatar>
      </template>
      <template v-slot:[`item.projects`]="{ item }">
        <div v-html="item.projects"></div>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAt | dateTime }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          v-if="item.deleteable"
          text
          small
          color="primary"
          @click="showedit(item._id)"
        >
          编辑
        </v-btn>
      </template>
    </v-data-table>
    <v-pagination
      v-if="length > 1"
      v-model="page"
      :page="page"
      :length="length"
      total-visible="10"
    />
    <v-dialog v-model="userModel" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ addtitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="姓名"
                  v-model="accountinfo.name"
                  required
                  placeholder="请输入姓名"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="手机号码"
                  v-model="accountinfo.mobile"
                  required
                  placeholder="请输入手机号码"
                  :disabled="addtitle == '编辑账户'"
                  hint="手机号是登录账号"
                  persistent-hint
                />
              </v-col>
              <v-col cols="12">
                <p style="color: #666; font-size: 16px">头像</p>
                <upload
                  @uploadfinish="setavatar"
                  :img="accountinfo.avatar"
                  :accept="'image/jpeg,image/png'"
                  style="width: 200px; height: 190px; padding: 0"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancelsave">关闭</v-btn>
          <v-btn color="blue darken-1" dark @click="saveaccount">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "underscore";
import upload from "@/components/upload";
export default {
  name: "UserList",
  data() {
    return {
      headers: [
        { text: "头像", value: "avatar", align: "center", width: 30 },
        { text: "姓名", value: "name", align: "center", width: 90 },
        { text: "手机号码", value: "mobile", align: "center", width: 125 },
        { text: "创建时间", value: "createdAt", align: "center", width: 120 },
        { text: "操作", value: "action", align: "center", width: 190 },
      ],
      page: 1,
      limit: 10,
      list: [],
      length: 0,
      userModel: false,
      addtitle: "",
      accountinfo: { _id: "", name: "", mobile: "", avatar: "" },
      nameRules: [(v) => !!v || "请输入姓名"],
      mobileRules: [
        (v) => !!v || "请输入手机号",
        (v) =>
          (v.length <= 11 && /^1[3456789]\d{9}$/.test(v)) ||
          "请输入正确的手机号",
      ],
    };
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "加载数据" });
      this.$api
        .doAPI({
          Action: "ListAccount",
          Data: { page: this.page, limit: this.limit },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.list = res.data.list;
            this.length = res.data.length;
            if (this.length > 0 && this.page > this.length) this.page--;
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    showadd() {
      this.accountinfo = {
        _id: "",
        name: "",
        mobile: "",
        avatar: "",
      };
      this.addtitle = "新增账户";
      this.userModel = true;
    },
    showedit(_id) {
      this.accountinfo = { ..._.findWhere(this.list, { _id }) };
      this.addtitle = "编辑账户";
      this.userModel = true;
    },
    cancelsave() {
      this.userModel = false;
    },
    setavatar(avatar) {
      this.accountinfo.avatar = avatar;
    },
    saveaccount() {
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({
          Action: "SaveAccount",
          Data: { accountinfo: this.accountinfo },
        })
        .then((res) => {
          this.$toast.clear();
          // console.log(res);
          if (res.code === 0) {
            this.userModel = false;
            this.getdata();
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
  },
  created() {},
  activated() {
    this.getdata();
  },
  components: {
    upload,
  },
  watch: {
    page(newPage, oldPage) {
      this.getdata();
    },
  },
};
</script>
<style scoped>
.v-data-table {
  width: 100%;
}
input[type="file"] {
  position: absolute;
  left: -99999px;
}
.content {
  padding: 20px 10px 54px;
}
</style>