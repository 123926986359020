<template>
  <div class="content">
    <div class="item">
      <v-row>
        <v-col cols="3">
          <v-select
            :items="cats"
            v-model="cat"
            label="场地大类别"
            outlined
            dense
          />
        </v-col>
        <v-col cols="3" v-if="cat === '场馆'">
          <v-select
            :items="tags"
            v-model="tag"
            item-text="name"
            item-value="_id"
            label="场地小类别"
            outlined
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span id="search">
            <input type="text" placeholder="场地名称" v-model="searchtext" />
            <span @click="search">搜索</span>
          </span>
        </v-col>
      </v-row>
    </div>
    <van-empty v-if="list.length === 0" description="暂无数据"></van-empty>
    <v-data-table
      v-else
      hide-default-footer
      :headers="headers"
      :items="list"
      :items-per-page="list.length"
      class="elevation-1"
      disable-sort
      no-data-text="暂无数据"
    >
      <template v-slot:[`item.cover`]="{ item }">
        <v-img
          class="img"
          :src="
            item.cover || 'https://scdn.xidong360.com/zzty/images/no-image.png'
          "
        />
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          v-if="item.recommend === 0"
          text
          x-small
          color="success"
          @click="recommend(item._id)"
        >
          推荐
        </v-btn>
        <v-btn text x-small color="primary" @click="edit(item._id)">编辑</v-btn>
        <v-btn text x-small color="error" @click="remove(item._id)">删除</v-btn>
      </template>
    </v-data-table>
    <v-pagination
      v-if="length > 1"
      v-model="page"
      :page="page"
      :length="length"
      total-visible="10"
    />
  </div>
</template>

<script>
export default {
  name: "VenueList",
  data() {
    return {
      cat: "全部",
      cats: [],
      tag: "",
      tags: [],
      page: 1,
      limit: 10,
      list: [],
      length: 0,
      headers: [
        { text: "编号", value: "serial_number" },
        { text: "类别", value: "cat" },
        { text: "名称", value: "name", align: "center" },
        { text: "封面", value: "cover", align: "center" },
        { text: "联系人", value: "linkman", align: "center" },
        { text: "联系电话", value: "linkphone", align: "center" },
        { text: "操作", value: "action", align: "center", width: 190 },
      ],
      searchtext: "",
    };
  },
  methods: {
    search() {
      if (this.page !== 1) this.page = 1;
      else this.getdata();
    },
    gettags() {
      this.$api
        .doAPI({
          Action: "VenueTags",
        })
        .then((res) => {
          if (res.code === 0) {
            this.cats = ["全部", ...res.data.cats];
            this.tags = [{ _id: "", name: "全部" }, ...res.data.tags];
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getdata() {
      this.$toast.loading({ message: "加载数据" });
      this.$api
        .doAPI({
          Action: "VenueList",
          Data: {
            page: this.page,
            limit: this.limit,
            cat: this.cat,
            tag: this.tag,
            name: this.searchtext,
          },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.list = res.data.list;
            this.length = res.data.length;
            if (this.length > 0 && this.page > this.length) this.page--;
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    recommend(venueId) {
      this.$dialog
        .confirm({ title: "提示", message: "推荐场地" })
        .then(() => {
          this.$toast.loading({ message: "" });
          this.$api
            .doAPI({
              Action: "RecommendVenue",
              Data: { venueId },
            })
            .then((res) => {
              this.$toast.clear();
              if (res.code === 0) {
                this.getdata();
              } else {
                this.$dialog.alert({ title: "提示", message: res.msg });
              }
            })
            .catch((err) => {
              this.$toast.clear();
              console.error(err);
            });
        })
        .catch(() => {});
    },
    edit(id) {
      this.$router.push({ path: "/venue/add", query: { id } });
    },
    remove(venueId) {
      this.$dialog
        .confirm({ title: "提示", message: "删除场地" })
        .then(() => {
          this.$toast.loading({ message: "" });
          this.$api
            .doAPI({
              Action: "RemoveVenue",
              Data: { venueId },
            })
            .then((res) => {
              this.$toast.clear();
              if (res.code === 0) {
                this.getdata();
              } else {
                this.$dialog.alert({ title: "提示", message: res.msg });
              }
            })
            .catch((err) => {
              this.$toast.clear();
              console.error(err);
            });
        })
        .catch(() => {});
    },
  },
  created() {},
  activated() {
    this.searchtext = "";
    this.gettags();
    this.getdata();
  },
  components: {},
  watch: {
    page() {
      this.getdata();
    },
    cat() {
      this.tag = "";
    },
  },
  mounted() {},
};
</script>
<style  lang='less' scoped>
.img {
  max-width: 200px;
  max-height: 110px;
}
.content {
  padding: 20px 10px 54px;
  .item {
    .col {
      padding-top: 0;
      padding-bottom: 0;
    }
    #search {
      display: flex;
      position: relative;
      padding-bottom: 12px;
      input {
        display: block;
        border: 1px solid #ddd;
        width: 100%;
        height: 40px;
        padding-left: 10px;
        font-size: 14px;
      }
      span {
        position: absolute;
        width: 60px;
        height: 40px;
        background: #4d95dc;
        color: #fff;
        right: 0;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>