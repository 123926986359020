import * as types from './types'
import _ from 'underscore'

const mutations = {
  [types.SET_MODULES]: (state, data) => {
    state.modules = data
  },
  [types.SET_NAV_TITLE]: (state, data) => {
    state.navTitle = data
  },
  [types.SET_NAV_LOGO]: (state, data) => {
    state.navLogo = data
  },
  [types.SET_GUANGCHANGWU]: (state, data) => {
    state.guangchangwu = data
  },
}
export default mutations
