<template>
  <div class="content">
    <div class="item">
      <v-row>
        <v-col cols="3">
          <v-select
            :items="cats"
            v-model="catId"
            item-text="name"
            item-value="_id"
            label="选择分类"
            outlined
          />
        </v-col>
        <v-col cols="3">
          <v-select
            :items="pstates"
            v-model="publish"
            item-text="text"
            item-value="value"
            label="发布状态"
            outlined
          />
        </v-col>
        <v-col cols="3">
          <v-select
            :items="states"
            v-model="state"
            item-text="text"
            item-value="value"
            label="赛事状态"
            outlined
          />
        </v-col>
        <v-col cols="3">
          <v-btn style="width: 90%" x-large color="primary" @click="search">
            搜索
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <van-empty
      v-if="List.length === 0"
      description="暂无数据"
      style="padding-top: 50px"
    />
    <div class="container-water-fall case">
      <waterfall
        :col="col"
        :gutterWidth="20"
        :data="List"
        @loadmore="onLoadMore"
        :height="'72vh'"
        class="listbar"
        :lazyDistance="200"
      >
        <template>
          <div
            class="case_item cell-item"
            v-for="(item, index) in List"
            :key="index"
            @click="
              $router.push({
                path: '/matchDetail/basicinfo',
                query: { id: item._id },
              })
            "
          >
            <v-btn class="cat" small color="warning" dark>
              {{ item.catname }}
            </v-btn>
            <div class="imgs">
              <img
                :src="item.cover + '?x-oss-process=style/thumb'"
                class="image"
              />
            </div>
            <h3>
              <span>{{ item.name }}</span>
            </h3>
            <p class="time">
              <span>
                <v-btn
                  v-if="item.matchstate === 0"
                  style="margin-right: 10px; cursor: default"
                  x-small
                  color="warning"
                  dark
                >
                  报名即将开始
                </v-btn>
                <v-btn
                  v-if="item.matchstate === 1"
                  style="margin-right: 10px; cursor: default"
                  x-small
                  color="success"
                  dark
                >
                  火热招募中
                </v-btn>
                <v-btn
                  v-if="item.matchstate === 2"
                  style="margin-right: 10px; cursor: default"
                  x-small
                  color="warning"
                  dark
                >
                  报名结束
                </v-btn>
                <v-btn
                  v-if="item.matchstate === 3"
                  style="margin-right: 10px; cursor: default"
                  x-small
                  color="success"
                  dark
                >
                  比赛进行中
                </v-btn>
                <v-btn
                  v-if="item.matchstate === 4"
                  style="margin-right: 10px; cursor: default"
                  x-small
                  color="#ccc"
                  dark
                >
                  比赛已结束
                </v-btn>
                <v-btn
                  v-if="item.matchstate === 5"
                  style="margin-right: 10px; cursor: default"
                  x-small
                  color="error"
                  dark
                >
                  比赛推迟
                </v-btn>
                <v-btn
                  v-if="item.publish === 'online'"
                  color="success"
                  x-small
                  dark
                  @click.prevent.stop="dounpublish(item._id)"
                >
                  已发布
                </v-btn>
                <v-btn
                  v-else
                  color="pink"
                  x-small
                  dark
                  @click.prevent.stop="dopublish(item._id)"
                >
                  未发布
                </v-btn>
              </span>
            </p>
            <p></p>
            <div class="action">
              <span></span>
            </div>
          </div>
        </template>
      </waterfall>
    </div>
  </div>
</template>
<script>
export default {
  name: "MatchList",
  data() {
    return {
      cats: [],
      catId: "",
      pstates: [
        { text: "全部", value: "" },
        { text: "未发布", value: "offline" },
        { text: "已发布", value: "online" },
      ],
      publish: "",
      states: [
        { text: "全部", value: "" },
        { text: "报名即将开始", value: "0" },
        { text: "火热招募中", value: "1" },
        { text: "报名结束", value: "2" },
        { text: "比赛进行中", value: "3" },
        { text: "比赛已结束", value: "4" },
      ],
      state: "",
      page: 1,
      limit: 24,
      length: 0,
      loadmore: false,
      col: 4,
      width: document.body.clientWidth,
      List: [],
    };
  },
  methods: {
    search() {
      if (this.page !== 1) this.page = 1;
      else this.getdata();
    },
    getallcats() {
      this.$api
        .doAPI({ Action: "AllMatchCats" })
        .then((res) => {
          if (res.code === 0) {
            this.cats = [{ _id: "", name: "全部" }, ...res.data.cats];
            this.getdata();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getdata() {
      this.$toast.loading({ message: "加载数据" });
      this.$api
        .doAPI({
          Action: "ListMatch",
          Data: {
            page: this.page,
            limit: this.limit,
            catId: this.catId,
            publish: this.publish,
            state: this.state,
          },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            if (this.page === 1) {
              this.List = [];
              setTimeout(() => {
                this.List = res.data.list;
              }, 50);
            } else {
              this.List = this.List.concat(res.data.list);
            }
            this.length = res.data.length;
            this.loadmore = res.data.loadmore;
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    dopublish(matchId) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "发布赛事",
        })
        .then(() => {
          this.$toast.loading({ message: "" });
          this.$api
            .doAPI({
              Action: "PublishMatch",
              Data: { matchId },
            })
            .then((res) => {
              this.$toast.clear();
              if (res.code === 0) {
                this.List.forEach((item) => {
                  if (item._id === matchId) item.publish = "online";
                });
              } else {
                this.$dialog.alert({ title: "提示", message: res.msg });
              }
            })
            .catch((err) => {
              this.$toast.clear();
              console.error(err);
            });
        })
        .catch(() => {});
    },
    dounpublish(matchId) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "撤下赛事",
        })
        .then(() => {
          this.$toast.loading({ message: "" });
          this.$api
            .doAPI({
              Action: "UnPublishMatch",
              Data: { matchId },
            })
            .then((res) => {
              this.$toast.clear();
              if (res.code === 0) {
                this.List.forEach((item) => {
                  if (item._id === matchId) item.publish = "offline";
                });
              } else {
                this.$dialog.alert({ title: "提示", message: res.msg });
              }
            })
            .catch((err) => {
              this.$toast.clear();
              console.error(err);
            });
        })
        .catch(() => {});
    },
    onLoadMore() {
      console.log("当前页面");
      if (this.page <= this.length && this.loadmore) {
        this.page++;
      }
    },
    getcol() {
      if (this.width > 1580) {
        this.col = 4;
      } else if (this.width > 1024) {
        this.col = 3;
      } else if (this.width > 768) {
        this.col = 2;
      }
    },
    resize(size) {
      console.log(size);
    },
  },
  computed: {},
  created() {
    this.getcol();
  },
  activated() {
    this.List = [];
    if (this.page !== 1) this.page = 1;
    this.getallcats();
    window.addEventListener("resize", () => {
      this.width = document.body.clientWidth;
    });
  },
  deactivated() {
    window.removeEventListener("resize", () => {});
  },
  components: {},
  watch: {
    page() {
      this.getdata();
    },
    width() {
      this.getcol();
    },
  },
};
</script>
<style scoped lang='less'>
.content {
  padding: 20px 10px 54px;
}
.listbar::-webkit-scrollbar {
  width: 2px;
}
.item {
  height: 100px;
}
.listbar::-webkit-scrollbar-track {
  background-color: #ddd;
}
.listbar::-webkit-scrollbar-thumb {
  background-color: #3d8bd9;
}
.case {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -20px;
}
.case .case_item {
  position: relative;
  padding: 0 0px 10px;
  // overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  // margin-bottom: 30px;
  cursor: pointer;
  margin: 0 20px 20px;
}
.case .case_item p {
  margin: 10px 0;
}
.case .case_item p.tags {
  color: #5ebcd3;
}
.case .case_item h3 {
  font-weight: 600;
  font-size: 16px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  span {
  }
}
.case .case_item .imgs {
  width: 100%;
  overflow: hidden;
  text-align: center;
}
.case .case_item .imgs img {
  border-radius: 5px 5px 0 0;
  width: 100%;
  transition: all 0.6s;
}
.case .case_item p.time {
  margin: 10px;
  font-size: 14px;
  color: #aaa;
}
.cat {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(251, 140, 0, 0.9) !important;
  z-index: 1;
}
.close {
  position: absolute;
  top: -15px;
  right: -15px;
  font-size: 30px;
}
</style>