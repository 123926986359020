<template>
  <div class="content">
    <div class="item">
      <v-row>
        <v-col cols="3">
          <v-select :items="authors" v-model="author" label="来源" outlined />
        </v-col>
        <v-col cols="3">
          <v-select
            :items="cats"
            v-model="catId"
            item-text="name"
            item-value="_id"
            label="分类"
            outlined
          />
        </v-col>
        <v-col cols="3">
          <v-select
            :items="pstates"
            v-model="publish"
            item-text="text"
            item-value="value"
            label="发布状态"
            outlined
          />
        </v-col>
        <v-col cols="3">
          <v-btn style="width: 90%" x-large color="primary" @click="search">
            搜索
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="items">
      <van-empty v-if="list.length === 0" description="暂无数据" />
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="left">
          <v-img
            class="img"
            :src="
              item.cover ||
              'https://cdn.vuetifyjs.com/images/cards/sunshine.jpg'
            "
          />
        </div>
        <div class="right">
          <div class="top">
            <span>{{ item.title }}</span>
            <v-btn small color="indigo" style="cursor: default; color: white">
              {{ item.author }}
            </v-btn>
          </div>
          <p class="time">
            {{ item.createdAt | dateTime("YYYY-MM-DD HH:mm") }}
          </p>
          <p class="summary">{{ item.summary }}</p>
          <div class="actions">
            <v-btn
              small
              outlined
              style="cursor: default"
              :color="item.publish === 'online' ? 'success' : 'error'"
            >
              {{ item.publish === "online" ? "已发布" : "未发布" }}
            </v-btn>
            <v-btn small outlined :color="item.catcolor">
              {{ item.catname }}
            </v-btn>
            <span style="margin-right: 15px">
              <v-btn
                v-if="loadnewpage > 0"
                small
                style="margin-right: 15px"
                color="success"
                v-clipboard:success="clipboardSuccessHandler"
                v-clipboard:error="clipboardErrorHandler"
                v-clipboard="
                  item.url || `https://zhengzhoutiyu.cn/web/zz/news/${item._id}`
                "
              >
                复制链接
              </v-btn>
              <v-btn
                small
                style="margin-right: 15px"
                color="primary"
                @click="
                  $router.push({
                    path: '/news/add',
                    query: { id: item._id },
                  })
                "
              >
                编辑
              </v-btn>
              <v-btn
                v-if="item.publish === 'offline'"
                style="margin-right: 15px"
                small
                @click.prevent.stop="dopublish(item._id)"
                color="success"
              >
                发布
              </v-btn>
              <v-btn
                v-else
                style="margin-right: 15px"
                small
                @click.prevent.stop="dounpublish(item._id)"
                color="error"
              >
                撤下
              </v-btn>
              <v-btn small color="error" @click.prevent.stop="remove(item._id)">
                删除
              </v-btn>
            </span>
          </div>
        </div>
      </div>
      <v-pagination
        v-if="length > 1"
        v-model="page"
        :page="page"
        :length="length"
        total-visible="10"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Clipboard from "v-clipboard";
Vue.use(Clipboard);
export default {
  name: "NewsList",
  data() {
    return {
      author: "全部",
      authors: [],
      tag: "",
      tags: [],
      catId: "",
      cats: [],
      pstates: [
        { text: "全部", value: "" },
        { text: "未发布", value: "offline" },
        { text: "已发布", value: "online" },
      ],
      publish: "",
      page: 1,
      limit: 5,
      list: [],
      length: 0,
      loadnewpage: 0,
    };
  },
  methods: {
    search() {
      if (this.page !== 1) this.page = 1;
      else this.getdata();
    },
    getauthors() {
      this.$api
        .doAPI({ Action: "NewsAuthors" })
        .then((res) => {
          if (res.code === 0) {
            this.authors = ["全部", ...res.data.authors];
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getcats() {
      this.$api
        .doAPI({ Action: "NewsCats" })
        .then((res) => {
          if (res.code === 0) {
            this.cats = [{ _id: "", name: "全部" }, ...res.data.cats];
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    gettags() {
      this.$api
        .doAPI({ Action: "NewsTags" })
        .then((res) => {
          if (res.code === 0) {
            this.tags = [{ _id: "", name: "全部" }, ...res.data.tags];
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getdata() {
      this.loadnewpage = 0;
      this.$toast.loading({ message: "加载数据" });
      this.$api
        .doAPI({
          Action: "NewsList",
          Data: {
            page: this.page,
            limit: this.limit,
            author: this.author,
            catId: this.catId,
            publish: this.publish,
          },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.list = res.data.list;
            this.length = res.data.length;
            if (this.length > 0 && this.page > this.length) this.page--;
            setTimeout(() => {
              this.loadnewpage = Date.now();
            }, 100);
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    dopublish(newsId) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "新闻资讯发布后，用户将会看到发布内容，请确认无误后再发布",
        })
        .then(() => {
          this.$toast.loading({ message: "" });
          this.$api
            .doAPI({
              Action: "PublishNews",
              Data: { newsId },
            })
            .then((res) => {
              this.$toast.clear();
              if (res.code === 0) {
                this.getdata();
              } else {
                this.$dialog.alert({ title: "提示", message: res.msg });
              }
            })
            .catch((err) => {
              this.$toast.clear();
              console.error(err);
            });
        })
        .catch(() => {});
    },
    dounpublish(newsId) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "新闻资讯撤下后，用户将不会看到发布内容",
        })
        .then(() => {
          this.$toast.loading({ message: "" });
          this.$api
            .doAPI({
              Action: "UnPublishNews",
              Data: { newsId },
            })
            .then((res) => {
              this.$toast.clear();
              if (res.code === 0) {
                this.getdata();
              } else {
                this.$dialog.alert({ title: "提示", message: res.msg });
              }
            })
            .catch((err) => {
              this.$toast.clear();
              console.error(err);
            });
        })
        .catch(() => {});
    },
    remove(newsId) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "新闻资讯删除后，不可恢复",
        })
        .then(() => {
          this.$toast.loading({ message: "" });
          this.$api
            .doAPI({
              Action: "RemoveNews",
              Data: { newsId },
            })
            .then((res) => {
              this.$toast.clear();
              if (res.code === 0) {
                this.getdata();
              } else {
                this.$dialog.alert({ title: "提示", message: res.msg });
              }
            })
            .catch((err) => {
              this.$toast.clear();
              console.error(err);
            });
        })
        .catch(() => {});
    },
    clipboardSuccessHandler({ value, event }) {
      console.log("success", value);
      this.$toast("已复制");
    },
    clipboardErrorHandler({ value, event }) {
      console.log("error", value);
    },
  },
  created() {},
  activated() {
    this.getauthors();
    this.getcats();
    this.getdata();
  },
  watch: {
    page() {
      this.getdata();
    },
  },
};
</script>
<style scoped lang='less'>
.v-data-table {
  width: 100%;
}
input[type="file"] {
  position: absolute;
  left: -99999px;
}
.content {
  padding: 20px 10px 54px;
  // display: flex;
  // justify-content: flex-start;
  // flex-wrap: wrap;
  // align-content: flex-start;
  .items {
    width: 100%;
    padding: 30px;
    padding-top: 0;
    .item {
      margin: 0 auto 10px;
      display: flex;
      justify-content: flex-start;
      padding-bottom: 20px;
      border-bottom: 2px solid #ccc;
      .left {
        width: 20%;
        margin-right: 20px;
        .img {
          max-width: 200px;
          max-height: 110px;
        }
      }
      .right {
        width: 80%;
        .top {
          width: 100;
          display: flex;
          justify-content: space-between;
          font-size: 20px;
          span {
            margin-right: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            font-family: "Microsoft Yahei";
            color: #666;
          }
        }
        p.time {
          font-size: 18px;
          padding: 0;
          margin: 0;
          color: #3fade8;
        }
        p.summary {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin-bottom: 10px;
          font-size: 16px;
          color: #666;
        }
        .actions {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1025px) {
  .content .item p.summary {
    -webkit-line-clamp: 3 !important;
  }
}
@media (min-width: 1025px) {
  .content .item p.summary {
    -webkit-line-clamp: 5 !important;
  }
}
@media (min-width: 1540px) {
  .content .item {
    .left {
      width: 25% !important;
    }
    .right {
      width: 75% !important;
    }
  }
}
@media (min-width: 1772px) {
  .content .item {
    .left {
      width: 20% !important;
    }
    .right {
      width: 80% !important;
    }
  }
}
</style>