<template>
  <div class="content" style="background-color: #f7f7f7">
    <v-form
      style="width: 95%; margin: 0 auto 80px; background: #fff; padding: 0 20px"
    >
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <p style="color: #666; font-size: 16px">封面图</p>
            <upload
              id="uploadvenuecover"
              :img="venue.cover"
              @uploadfinish="setcover"
              :accept="'image/jpeg,image/png'"
            />
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-row justify="center" align="center">
              <v-col cols="12">
                <v-text-field
                  label="场地名称"
                  placeholder="场地名称"
                  v-model="venue.name"
                  required
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="cats"
                  v-model="venue.cat"
                  label="场地大类别"
                  placeholder="场地大类别"
                  dense
                >
                </v-select>
              </v-col>
              <v-col cols="12" v-if="venue.cat === '场馆'">
                <v-select
                  :items="tags"
                  v-model="venue.tags"
                  item-text="name"
                  item-value="_id"
                  label="场地小类别"
                  placeholder="场地小类别"
                  multiple
                  dense
                >
                </v-select>
              </v-col>
              <v-col cols="12" v-if="venue.cat === '场馆'">
                <v-select
                  :items="labels"
                  v-model="venue.labels"
                  item-text="name"
                  item-value="_id"
                  label="运动类型"
                  placeholder="运动类型"
                  multiple
                  dense
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="areas"
                  v-model="venue.area"
                  label="所属区域"
                  placeholder="所属区域"
                  dense
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="负责人姓名"
                  placeholder="负责人姓名"
                  v-model="venue.linkman"
                  required
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="联系电话"
                  placeholder="联系电话"
                  v-model="venue.linkphone"
                  required
                  dense
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <p style="color: #666; font-size: 16px">场地位置</p>
            <maps
              v-if="fetched"
              id="editmap"
              ref="venuemap"
              @setlocation="setlocation"
              :location="{
                address: venue.address,
                latitude: venue.latitude,
                longitude: venue.longitude,
              }"
              style="width: 100%; height: 300px"
            />
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-row justify="center" align="center">
              <v-col cols="12">
                <v-text-field
                  v-model="venue.address"
                  label="场地位置"
                  placeholder="场地位置"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="lnglat"
                  label="经纬度"
                  placeholder="经纬度"
                  hint="格式为 经度,纬度 如：113.411468,34.773356"
                  persistent-hint
                  dense
                />
              </v-col>
              <v-col cols="12">
                <p style="color: #666; font-size: 16px">场地简介</p>
                <v-textarea
                  label="场地简介"
                  v-model="venue.desc"
                  outlined
                  rows="4"
                  row-height="25"
                  shaped
                  dense
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="第三方小程序"
              placeholder="第三方小程序"
              v-model="venue.miniapp"
              dense
            />
          </v-col>
          <v-col cols="12">
            <p style="color: #666; font-size: 16px">场地介绍</p>
            <quill
              @send="setinfo"
              :content="venue.info"
              placeholder="场地介绍"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <div class="actions">
      <v-btn
        color="primary"
        x-large
        style="width: 300px"
        class="mr-4"
        @click="savaVenue"
      >
        保存
      </v-btn>
    </div>
  </div>
</template>

<script>
import _ from "underscore";
import Vue from "vue";
import quill from "@/components/quill";
import upload from "@/components/upload";
import maps from "@/components/map";
export default {
  name: "VenueAdd",
  data() {
    return {
      cats: [],
      tags: [],
      labels: [],
      areas:[],
      fetched: false,
      venue: {
        name: "",
        cat: "",
        tags: [],
        linkman: "",
        linkphone: "",
        address: "",
        longitude: "",
        latitude: "",
        desc: "",
        info: "",
        cover: "",
      },
      lnglat: "",
    };
  },
  watch: {
    lnglat(lnglat) {
      if (lnglat && lnglat.indexOf(",") > -1) {
        lnglat = lnglat.split(",");
        this.venue.longitude = lnglat[0];
        this.venue.latitude = lnglat[1];
        if (this.$refs.venuemap) {
          this.$refs.venuemap.setcenter(this.venue);
        }
      } else {
        this.venue.longitude = "";
        this.venue.latitude = "";
      }
    },
  },
  computed: {},
  methods: {
    gettags() {
      this.$toast.loading({ message: "加载数据" });
      this.$api
        .doAPI({ Action: "VenueTags", Data: { venueId: this.$route.query.id } })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.tags = [...res.data.tags];
            this.cats = [...res.data.cats];
            this.labels = [...res.data.labels];
            this.areas = [...res.data.areas];
            if (res.data.venue) {
              this.venue = res.data.venue;
              this.lnglat = `${res.data.venue.longitude},${res.data.venue.latitude}`;
            }
            this.fetched = true;
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    setlocation(location) {
      this.venue.address = location.address;
      this.venue.latitude = location.latitude;
      this.venue.longitude = location.longitude;
      this.lnglat = `${location.longitude},${location.latitude}`;
    },
    setinfo(info) {
      this.venue.info = info;
    },
    reset() {
      this.venue = {
        name: "",
        cat: "",
        tags: [],
        linkman: "",
        linkphone: "",
        address: "",
        longitude: "",
        latitude: "",
        desc: "",
        info: "",
        cover: "",
      };
      this.lnglat = "";
    },
    setcover(cover) {
      this.venue.cover = cover;
    },
    savaVenue() {
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({
          Action: "SaveVenue",
          Data: {
            venueId: this.$route.query.id,
            venueinfo: { ...this.venue },
          },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            // this.$router.push({ path: "/venue/list" });
            this.$toast({ message: "添加成功" });
            this.reset();
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          console.error(err);
          this.$toast.clear();
        });
    },
  },
  components: {
    quill,
    maps,
    upload,
  },
  created() {},
  activated() {
    this.reset();
    this.gettags();
  },
};
</script>
<style  scoped>
.content {
  padding: 20px 0 0;
}
#uploadvenuecover {
  padding-bottom: 10%;
}
.actions {
  padding-top: 30px;
  position: relative;
  width: 100%;
  height: 100px;
  background: #fcfcfc;
  bottom: 0;
  z-index: 9;
  text-align: center;
}
</style>