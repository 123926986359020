var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[(_vm.list.length === 0)?_c('van-empty',{attrs:{"description":"暂无数据"}}):_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.list,"items-per-page":_vm.list.length,"disable-sort":"","no-data-text":"暂无数据"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.exportrepaire}},[_c('v-icon',{staticClass:"mr-1",attrs:{"dark":"","size":"20"}},[_vm._v(" mdi-cloud-download ")]),_vm._v(" 下载 ")],1)]},proxy:true},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.nickname)+" ")]}},{key:"item.reply",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.showreply2(item)}}},[_vm._v(" 查看("+_vm._s(item.reply.length)+") ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(item.createdAt,"YYYY-MM-DD HH:mm:ss"))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.showreply(item._id)}}},[_vm._v(" 回复 ")]),_c('v-btn',{attrs:{"text":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.remove(item._id)}}},[_vm._v("删除")])]}}],null,true)}),(_vm.length > 1)?_c('v-pagination',{attrs:{"page":_vm.page,"length":_vm.length,"total-visible":"10"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.replymodel),callback:function ($$v) {_vm.replymodel=$$v},expression:"replymodel"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("回复")])]),_c('v-card-text',[_c('v-row',{staticClass:"pb-0 mb-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"回复内容","placeholder":"请输入回复内容","outlined":"","rows":"5","row-height":"25","shaped":"","dense":""},model:{value:(_vm.reply),callback:function ($$v) {_vm.reply=$$v},expression:"reply"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","dark":""},on:{"click":_vm.doreply}},[_vm._v("保存")]),_c('v-btn',{on:{"click":function($event){_vm.replymodel = false}}},[_vm._v("关闭")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.reply2model),callback:function ($$v) {_vm.reply2model=$$v},expression:"reply2model"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("回复内容")])]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","headers":_vm.replyheaders,"items":_vm.replylist,"items-per-page":_vm.replylist.length,"disable-sort":"","no-data-text":"暂无数据"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.reply2model = false}}},[_vm._v("关闭")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }