<template>
  <div class="content">
    <van-empty v-if="list.length === 0" description="暂无数据"></van-empty>
    <v-data-table
      v-else
      hide-default-footer
      :headers="headers"
      :items="list"
      :items-per-page="list.length"
      class="elevation-1"
      disable-sort
      no-data-text="暂无数据"
    >
      <template v-slot:[`item.user`]="{ item }">
        {{ item.user.nickname }}
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAt | dateTime("YYYY-MM-DD HH:mm") }}
      </template>
      <!-- <template v-slot:[`item.action`]="{ item }">
        <v-btn text x-small color="success" @click="edit(item._id)">编辑</v-btn>
        <v-btn text x-small color="error" @click="remove(item._id)">删除</v-btn>
      </template> -->
    </v-data-table>
    <v-pagination
      v-if="length > 1"
      v-model="page"
      :page="page"
      :length="length"
      total-visible="10"
    />
  </div>
</template>

<script>
export default {
  name: "FeedbackList",
  data() {
    return {
      page: 1,
      limit: 10,
      list: [],
      length: 0,
      headers: [
        { text: "用户", value: "user" },
        { text: "内容", value: "content", align: "center" },
        { text: "时间", value: "createdAt", align: "center" },
        { text: "操作", value: "action", align: "center", width: 190 },
      ],
    };
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "加载数据" });
      this.$api
        .doAPI({
          Action: "FeedbackList",
          Data: { page: this.page, limit: this.limit },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.list = res.data.list;
            this.length = res.data.length;
            if (this.length > 0 && this.page > this.length) this.page--;
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
  },
  created() {},
  activated() {
    this.getdata();
  },
  components: {},
  watch: {
    page() {
      this.getdata();
    },
  },
  mounted() {},
};
</script>
<style scoped>
.content {
  padding: 20px 10px 54px;
}
</style>