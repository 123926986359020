<template>
  <div class="content">
    <div class="head">
      <div class="head_left">
        <v-btn color="primary" @click="showadd">
          <v-icon dark>mdi-plus</v-icon>添加
        </v-btn>
      </div>
    </div>
    <div class="main">
      <van-empty v-if="list.length === 0" description="暂无数据"></van-empty>
      <v-simple-table v-else>
        <thead>
          <tr>
            <th class="text-left">
              排序(可拖动)
              <v-btn v-if="sorted" text small @click="savesort">
                <v-icon color="error">mdi-arrow-up-down</v-icon>保存排序
              </v-btn>
              <v-btn v-else text small color="rgba(0,0,0,0)">
                <v-icon color="rgba(0,0,0,0)">mdi-arrow-up-down</v-icon>保存排序
              </v-btn>
            </th>
            <th class="text-left">封面</th>
            <th class="text-left">类型</th>
            <th class="text-left">操作</th>
          </tr>
        </thead>
        <tbody id="items">
          <tr v-for="item in list" :id="item._id" :key="item._id">
            <td>
              <v-icon>mdi-arrow-up-down</v-icon>
              {{ item.idx + 1 }}
            </td>
            <td>
              <v-img
                :src="item.cover"
                max-width="200"
                max-height="150"
                style="margin: 10px 0"
              />
            </td>
            <td>
              <div v-if="!!item.title" v-text="item.title"></div>
              <div v-if="item.type === 'nav'" class="blue--text">
                跳转当前小程序
              </div>
              <div v-else-if="item.type === 'xcx'" class="blue--text">
                打开其他小程序
              </div>
              <div v-else-if="item.type === 'web'" class="blue--text">
                跳转到已授权公众号文章
              </div>
              <div v-else class="blue--text">不跳转</div>
            </td>
            <td>
              <v-btn text x-small color="primary" @click="showedit(item._id)">
                编辑
              </v-btn>
              <v-btn text x-small color="error" @click="remove(item._id)">
                删除
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <v-dialog v-model="bannerModel" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ addtitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6" sm="6">
                <p style="color: #666; font-size: 16px">轮播图</p>
                <upload
                  @uploadfinish="setcover"
                  :img="bannerinfo.cover"
                  :accept="'image/jpeg,image/png'"
                  style="width: 320px; height: 160px; padding: 0"
                />
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <p style="color: #666; font-size: 16px">点击跳转到</p>
                <v-radio-group v-model="bannerinfo.type">
                  <v-radio label="不跳转" value="无"></v-radio>
                  <v-radio label="已授权公众号文章" value="web"></v-radio>
                  <v-radio label="当前小程序" value="nav"></v-radio>
                  <v-radio label="其他小程序" value="xcx"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="bannerinfo.title"
                  label="轮播标题"
                  placeholder="新版首页轮播标题"
                  required
                  dense
                />
              </v-col>
              <v-col cols="12" v-if="bannerinfo.type === 'xcx'">
                <v-text-field
                  v-model="bannerinfo.appId"
                  label="小程序appId"
                  placeholder="wxf1a349bdad2017eb"
                  required
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-if="bannerinfo.type === 'nav'"
                  v-model="bannerinfo.path"
                  label="跳转地址"
                  placeholder="/pages/index/index"
                  required
                  dense
                />
                <v-text-field
                  v-if="bannerinfo.type === 'xcx'"
                  v-model="bannerinfo.path"
                  label="跳转地址"
                  placeholder="/pages/index/index"
                  required
                  dense
                />
                <v-text-field
                  v-if="bannerinfo.type === 'web'"
                  v-model="bannerinfo.path"
                  label="跳转地址"
                  placeholder="https://mp.weixin.qq.com/s/yJvJD3a4rvv9Qjkwm5IrnA"
                  required
                  dense
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancelsave">关闭</v-btn>
          <v-btn color="blue darken-1" dark @click="savebanner">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "underscore";
import Sortable from "sortablejs";
import upload from "@/components/upload";
export default {
  name: "Banner",
  data() {
    return {
      list: [],
      sorted: false,
      sort: [],
      bannerIds: [],
      bannerinfo: {
        _id: "",
        cover: "",
        type: "无",
        appId: "",
        path: "",
        title:""
      },
      bannerModel: false,
      addtitle: "",
    };
  },
  components: {
    upload,
  },
  watch: {
    "$route.params"(params) {
      this.getdata();
    },
  },
  methods: {
    getdata() {
      this.sorted = false;
      this.$toast.loading({ message: "加载数据" });
      this.$api
        .doAPI({
          Action: "BannerList",
          Data: { position: this.$route.params.position },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.list = res.data.banners;
            this.sort = _.pluck(res.data.banners, "_id");
            let that = this;
            setTimeout(() => {
              let el = document.getElementById("items");
              let sortable = Sortable.create(el, {
                onEnd: function (evt) {
                  let oldIndex = evt.oldIndex;
                  let newIndex = evt.newIndex;
                  let to = evt.to;
                  let banners = [];
                  for (let i = 0; i < to.children.length; i++) {
                    banners.push({ _id: to.children[i].id });
                  }
                  that.sorted = that.checksort(banners);
                },
              });
            }, 100);
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    remove(bannerId) {
      this.$dialog
        .confirm({ title: "提示", message: "删除轮播图" })
        .then(() => {
          this.$toast.loading({ message: "" });
          this.$api
            .doAPI({
              Action: "RemoveBanner",
              Data: { bannerId },
            })
            .then((res) => {
              this.$toast.clear();
              if (res.code === 0) {
                this.getdata();
              } else {
                this.$dialog.alert({ title: "提示", message: res.msg });
              }
            })
            .catch((err) => {
              this.$toast.clear();
              console.error(err);
            });
        })
        .catch(() => {});
    },
    checksort(banners) {
      let sort = _.pluck(banners, "_id");
      this.bannerIds = sort;
      return sort.join("") !== this.sort.join("");
    },
    savesort() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "保存排序",
        })
        .then(() => {
          this.$toast.loading({ message: "" });
          this.$api
            .doAPI({
              Action: "BannerSort",
              Data: {
                bannerIds: this.bannerIds,
              },
            })
            .then((res) => {
              this.$toast.clear();
              if (res.code === 0) {
                this.getdata();
              } else {
                this.$dialog.alert({ title: "提示", message: res.msg });
              }
            })
            .catch((err) => {
              console.error(err);
              this.$toast.clear();
            });
        })
        .catch(() => {});
    },
    savebanner() {
      if (!this.bannerinfo.cover) {
        return this.$dialog.alert({ title: "提示", message: "轮播图不能为空" });
      }
      this.$toast.loading({ message: "" });
      console.log(this.bannerinfo);
      this.$api
        .doAPI({
          Action: "SaveBanner",
          Data: {
            bannerinfo: {
              ...this.bannerinfo,
              position: this.$route.params.position,
            },
          },
        })
        .then((res) => {
          this.$toast.clear();
          // console.log(res);
          if (res.code === 0) {
            this.bannerModel = false;
            this.getdata();
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    showadd() {
      this.bannerinfo = {
        _id: "",
        cover: "",
        type: "无",
        appId: "",
        path: "",
        title:"",
      };
      this.addtitle = "添加";
      this.bannerModel = true;
    },
    showedit(_id) {
      this.bannerinfo = { ..._.findWhere(this.list, { _id }) };
      this.addtitle = "编辑";
      this.bannerModel = true;
    },
    cancelsave() {
      this.bannerModel = false;
    },
    setcover(cover) {
      this.bannerinfo.cover = cover;
    },
  },
  created() {
    this.getdata();
  },
  activated() {},
};
</script>
<style lang='less' scoped>
.content {
  .head {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .head_left {
      font-size: 18px;
      span {
        margin-right: 10px;
      }
      .action {
        cursor: pointer;
        color: #3c8dbc;
        .back {
          color: #3c8dbc;
          margin-right: 5px;
        }
      }
    }
  }
}
.v-application .display-3 {
  font-size: 40px !important;
}
</style>
