<template>
  <div class="content">
    <div class="count">
      <ul>
        <li style="color: #409eff">
          <countTo
            class="countNum"
            :startVal="0"
            :endVal="venuecount"
            :duration="1000"
          ></countTo>
          <span class="titles">场地</span>
        </li>
        <li style="color: #67c23a">
          <countTo
            class="countNum"
            :startVal="0"
            :endVal="matchcount"
            :duration="1000"
          ></countTo>
          <span class="titles">赛事</span>
        </li>
        <li style="color: #e6a23c">
          <countTo
            class="countNum"
            :startVal="0"
            :endVal="newscount"
            :duration="1000"
          ></countTo>
          <span class="titles">新闻</span>
        </li>
        <li style="color: #f56c6c">
          <countTo
            class="countNum"
            :startVal="0"
            :endVal="circlecount"
            :duration="1000"
          ></countTo>
          <span class="titles">运动圈</span>
        </li>
      </ul>
    </div>
    <v-row style="margin-right: 0">
      <v-col cols="12" md="8" sm="12">
        <v-container
          style="
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            margin-bottom: 20px;
          "
        >
          <p class="item_title">场地列表</p>
          <van-empty v-if="venues.length === 0" description="暂无数据" />
          <v-row class="mb-6" v-else no-gutters style="margin-top: 20px">
            <v-col
              lg="4"
              md="6"
              xl="4"
              sm="6"
              style="padding: 10px 10px 10px 0px; margin-bottom: 0px"
              v-for="(item, index) in venues"
              :key="'venue_' + index"
            >
              <v-card class="mx-auto">
                <template>
                  <v-list-item>
                    <v-list-item-avatar style="width: 60px; height: 60px">
                      <v-img :src="item.cover+'?image_process=resize,w_120/crop,mid,w_120,h_120'" />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <p class="clamp clampTitle" style="font-size: 16px">
                        {{ item.name }}
                      </p>
                      <p
                        class="clamp clampDesc"
                        style="-webkit-line-clamp: 2; margin: 5px 0 0"
                      >
                        {{ item.desc }}
                      </p>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-container style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)">
          <p class="item_title">赛事活动</p>
          <van-empty v-if="matches.length === 0" description="暂无数据" />
          <v-row class="mb-6" v-else no-gutters style="margin-top: 20px">
            <v-col
              lg="4"
              md="6"
              xl="4"
              sm="6"
              v-for="(item, index) in matches"
              :key="'game_' + index"
              style="padding: 10px; margin-bottom: 20px"
            >
              <v-card
                class="mt-4 mx-auto match"
                @click="
                  $router.push({
                    path: '/matchDetail/basicinfo',
                    query: { id: item._id },
                  })
                "
              >
                <img
                  class="banner"
                  :src="item.cover + '?image_process=resize,w_750/crop,mid,w_750,h_468'"
                  alt
                />
                <v-card-text class="pt-0">
                  <div class="title font-weight-normal mb-2 clamp clampTitle">
                    {{ item.name }}
                  </div>
                  <div
                    class="subheading font-weight-light grey--text clamp clampDesc"
                    style="height: 44px"
                  >
                    {{ item.address }}
                  </div>
                  <v-divider class="my-2"></v-divider>
                  <v-icon class="mr-2" small>mdi-clock</v-icon>
                  <span class="caption grey--text font-weight-light">
                    {{ item.sdate | dateTime }}
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col
        cols="6"
        md="4"
        sm="12"
        style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); margin: 10px 0"
      >
        <div>
          <p class="item_title">动态</p>
          <van-empty v-if="dynamics.length === 0" description="暂无数据" />
          <div v-else class="item" v-for="(item, i) in dynamics" :key="i">
            <img class="rounded" :src="item.cover+'?image_process=resize,w_375/crop,mid,w_375,h_234'" />
            <div class="ptitle">
              <p>
                {{ item.title }}
              </p>
              <span>{{ item.createdAt | dateTimeFromNow }}</span>
              &nbsp;
              <span>{{ item.author }}</span>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import countTo from "vue-count-to";
export default {
  name: "WorkBench",
  data: () => ({
    matchcount: 0,
    venuecount: 0,
    newscount: 0,
    circlecount: 0,
    venues: [],
    matches: [],
    dynamics: [],
  }),
  components: {
    countTo,
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({ Action: "WorkBenchData" })
        .then((res) => {
          console.log(res);
          this.$toast.clear();
          if (res.code === 0) {
            this.venuecount = res.data.venues.count;
            this.matchcount = res.data.matches.count;
            this.newscount = res.data.news.count;
            this.circlecount = res.data.circles.count;
            this.venues = res.data.venues.list;
            this.matches = res.data.matches.list;
            let dynamics = res.data.news.list.concat(res.data.circles.list);
            dynamics.sort((a, b) => b.createdAt - a.createdAt);
            this.dynamics = dynamics;
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          console.error(err);
          this.$toast.clear();
        });
    },
  },
  created() {
    // this.getdata();
  },
  activated() {
    this.getdata();
  },
};
</script>
<style lang='less' scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
.count ul {
  width: 100%;
  height: 100px;
  display: flex;
  list-style: none;
  padding-left: 0;
  li {
    width: 25%;
    box-sizing: border-box;
    margin-right: 8px;
    height: 100px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    line-height: 100px;
    display: flex;
    justify-content: space-around;
    padding: 0 5px;
    .countNum {
      font-size: 48px;
      height: 100%;
    }
    .titles {
      font-size: 14px;
      line-height: 100px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.item {
  display: flex;
  justify-content: flex-start;
  height: 60px;
  border-bottom: 1px solid #e1e1e1;
  // .v-avatar {
  //   margin-right: 10px;
  //   margin-top: 5px;
  img {
    display: inline-block;
    margin: 10px;
    // width: 40px;
    height: 40px;
  }
  // }
  .ptitle {
    font-size: 14px;
    margin-top: 10px;
    span {
      font-size: 12px;
      color: #666;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 0;
      color: #1565c0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }
}
.match {
  .banner {
    position: relative;
    width: 90%;
    border-radius: 4px;
    margin: auto;
    top: -20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    height: 156px;
    object-fit: cover;
  }
  .v-card {
    .grey--text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
}
.item_title {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 10px;
  font-size: 16px;
  color: #409eff;
  margin-bottom: 0;
}
.clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.clampTitle {
  -webkit-line-clamp: 1;
}
.clampDesc {
  -webkit-line-clamp: 2;
}
@media (min-width: 1200px) and (max-width: 1264px) {
  .match {
    .banner {
      height: 170px;
    }
  }
}
@media (min-width: 1264px) and (max-width: 1480px) {
  .match {
    .banner {
      height: 120px;
    }
  }
}
@media (min-width: 1480px) and (max-width: 1662px) {
  .match {
    .banner {
      height: 130px;
    }
  }
}

.rounded{
  border-radius: 5px;
}
</style>
