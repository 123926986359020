<template>
  <div class="content">
    <van-empty v-if="list.length === 0" description="暂无数据"></van-empty>
    <v-data-table
      v-else
      hide-default-footer
      :headers="headers"
      :items="list"
      :items-per-page="list.length"
      class="elevation-1"
      disable-sort
      no-data-text="暂无数据"
    >
      <template v-slot:top>
        <v-btn color="primary" @click="exportrepaire">
          <v-icon class="mr-1" dark size="20"> mdi-cloud-download </v-icon>
          下载
        </v-btn>
      </template>
      <template v-slot:[`item.user`]="{ item }">
        {{ item.user.nickname }}
      </template>
      <template v-slot:[`item.reply`]="{ item }">
        <v-btn text x-small color="primary" @click="showreply2(item)">
          查看({{ item.reply.length }})
        </v-btn>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAt | dateTime("YYYY-MM-DD HH:mm:ss") }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn text x-small color="primary" @click="showreply(item._id)">
          回复
        </v-btn>
        <v-btn text x-small color="error" @click="remove(item._id)">删除</v-btn>
      </template>
    </v-data-table>
    <v-pagination
      v-if="length > 1"
      v-model="page"
      :page="page"
      :length="length"
      total-visible="10"
    />
    <v-dialog v-model="replymodel" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">回复</span>
        </v-card-title>
        <v-card-text>
          <v-row class="pb-0 mb-0">
            <v-col cols="12">
              <v-textarea
                label="回复内容"
                placeholder="请输入回复内容"
                v-model="reply"
                outlined
                rows="5"
                row-height="25"
                shaped
                dense
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" dark @click="doreply">保存</v-btn>
          <v-btn @click="replymodel = false">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="reply2model" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">回复内容</span>
        </v-card-title>
        <v-card-text>
          <v-data-table
            hide-default-footer
            :headers="replyheaders"
            :items="replylist"
            :items-per-page="replylist.length"
            class="elevation-1"
            disable-sort
            no-data-text="暂无数据"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="reply2model = false">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import _ from "underscore";
export default {
  name: "FeedbackList",
  data() {
    return {
      page: 1,
      limit: 10,
      list: [],
      length: 0,
      headers: [
        { text: "用户", value: "user", width: 150 },
        { text: "标题", value: "desc", width: 180 },
        { text: "内容", value: "content" },
        { text: "回复", value: "reply", align: "center" },
        { text: "时间", value: "createdAt", width: 180 },
        { text: "操作", value: "action", align: "center", width: 190 },
      ],
      replyid: "",
      reply: "",
      replymodel: false,
      replyheaders: [
        { text: "内容", value: "c", width: 180 },
        { text: "时间", value: "t", width: 180 },
      ],
      replylist: [],
      reply2model: false,
    };
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "加载数据" });
      this.$api
        .doAPI({
          Action: "RepaireList",
          Data: { page: this.page, limit: this.limit },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.list = res.data.list;
            this.length = res.data.length;
            if (this.length > 0 && this.page > this.length) this.page--;
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    remove(id) {
      this.$dialog
        .confirm({ title: "提示", message: "删除报修信息" })
        .then(() => {
          this.$toast.loading({ message: "" });
          this.$api
            .doAPI({
              Action: "RemoveRepaire",
              Data: { id },
            })
            .then((res) => {
              this.$toast.clear();
              if (res.code === 0) {
                this.getdata();
              } else {
                this.$dialog.alert({ title: "提示", message: res.msg });
              }
            })
            .catch((err) => {
              this.$toast.clear();
              console.error(err);
            });
        })
        .catch(() => {});
    },
    doreply() {
      if (!this.reply)
        return this.$dialog.alert({
          title: "提示",
          message: "回复内容不能为空",
        });
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({
          Action: "ReplyRepaire",
          Data: { id: this.replyid, reply: this.reply },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.replymodel = false;
            this.getdata();
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    showreply(id) {
      this.replyid = id;
      this.reply = "";
      this.replymodel = true;
    },
    showreply2(item) {
      this.replylist = item.reply;
      this.reply2model = true;
    },
    exportrepaire() {
      let href = `/api/exportrepaire?t=${Date.now()}`;
      Cookies.set(
        "Authorization",
        `Bearer ${localStorage.getItem("zzty.token")}`,
        { expires: new Date(new Date().getTime() + 10 * 60 * 1000) }
      );
      this.$utils.downloadBrowser({ href });
    },
  },
  created() {},
  activated() {
    this.getdata();
  },
  components: {},
  watch: {
    page() {
      this.getdata();
    },
  },
  mounted() {},
};
</script>
<style scoped>
.content {
  padding: 20px 10px 54px;
}
</style>