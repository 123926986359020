<template>
  <v-app>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="#E58040"
      style="box-shadow:none"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-icon
        class="back"
        style="margin-right: 20px"
        @click="
          path.indexOf('/matchDetail') === 0
            ? $router.push({ path: '/match' })
            : $router.push({ path: '/venue' })
        "
        v-show="
          path.indexOf('/matchDetail') === 0 ||
          path.indexOf('/venueDetail') === 0
        "
        >mdi-home</v-icon
      >
      <v-avatar size="36px" v-if="navLogo">
        <img style="background: #fff" :src="navLogo" />
      </v-avatar>
      <v-toolbar-title style="width: 400px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">{{ navTitle }}</span>
      </v-toolbar-title>
      <!-- <v-text-field
        flat
        solo-inverted
        hide-details
        prepend-inner-icon="mdi-magnify"
        label="Search"
        class="hidden-sm-and-down"
      />-->
      <v-spacer />
      <!-- <v-btn icon>
        <v-icon>mdi-apps</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn>-->
      <v-menu bottom offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon large v-on="on">
            <v-avatar v-if="userinfo.avatar" size="36px" item>
              <v-img :src="userinfo.avatar" alt="Vuetify" />
            </v-avatar>
            <v-avatar v-else-if="userinfo.name" size="36" tile>
              <span class="white--text headline">
                {{ userinfo.name.substr(0, 1) }}
              </span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-img v-if="userinfo.avatar" :src="userinfo.avatar" />
                <v-avatar v-else-if="userinfo.name" size="36" tile>
                  <span class="white--text headline">
                    {{ userinfo.name.substr(0, 1) }}
                  </span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ userinfo.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ userinfo.role }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="logout">
                  <v-icon>mdi-logout-variant</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item
              v-for="(item, index) in lists"
              :key="index"
              @click="$router.push({ path: item.path })"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <!-- <v-switch v-model="$vuetify.theme.dark" class="mx-2" label="深色模式"></v-switch> -->
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showmodal = true">
              修改密码
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list dense expand>
        <template v-for="item in getmenus(path)">
          <v-list-group
            v-if="item.children"
            :key="item.text"
            :prepend-icon="item.icon"
            color
            no-action
            value="true"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              class="m-item"
              v-for="child in item.children"
              :key="child.path"
              link
              color="primary"
              :input-value="child.active"
              @click="goto(path, child.path)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else-if="item.path"
            :key="item.text"
            link
            :input-value="item.active"
            color="primary"
            @click="goto(path, item.path)"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-main id="overflow_box" style="padding-bottom: 15px">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </v-main>
    <v-dialog v-model="showmodal" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">修改密码</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  type="password"
                  label="新密码 *"
                  placeholder="请输入新密码"
                  v-model="newpwd"
                  :rules="passRules"
                  color="primary"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  type="password"
                  label="确认新密码 *"
                  placeholder="请再次输入新密码"
                  v-model="newpwd2"
                  :rules="passRules"
                  color="primary"
                />
              </v-col>
            </v-row>
          </v-container>
          <small>* 为必填项</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" dark @click="changepwd">保存</v-btn>
          <v-btn @click="showmodal = false">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-footer
      v-show="getmenus(path)[0].path == '/workbench'"
      absolute
      class="font-weight-medium"
    >
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} —
        <strong>@Copyright 河南喜动体育科技有限公司</strong>
      </v-col>
    </v-footer> -->
  </v-app>
</template>

<script>
import _ from "underscore";
import { mapState, mapGetters } from "vuex";
export default {
  name: "BasicLayout",
  data() {
    return {
      drawer: null,
      userinfo: {},
      path: this.$route.path,
      id: "",
      showmodal: false,
      newpwd: "",
      newpwd2: "",
      lists: [
        {
          icon: "mdi-account",
          text: "个人中心",
          path: "/user/info",
        },
      ],
      title: "郑州体育",
      passRules: [
        (v) => !!v || "请输入密码",
        (v) => (6 <= v.length && v.length <= 18) || "密码长度要求6-18位",
      ],
    };
  },
  computed: {
    ...mapState(["navTitle", "navLogo"]),
    ...mapGetters(["getmenus"]),
  },
  created() {
    this.getuserinfo();
  },
  mounted() {},
  watch: {
    $route(newVal, oldVal) {
      this.path = newVal.path;
    },
  },
  methods: {
    goto(path, cpath) {
      if (path !== cpath) {
        this.$router.push({
          path: cpath,
          query: this.$route.meta.qs
            ? _.pick(this.$route.query, this.$route.meta.qskeys || ["id"])
            : {},
        });
      }
    },
    getuserinfo() {
      this.$api
        .doAPI({ Action: "UserInfo" })
        .then((res) => {
          if (res.code === 0) {
            this.userinfo = res.data.user;
            this.$store.dispatch("SetModules", res.data.modules);
            if (res.data.match) {
              this.$store.dispatch("SetNavTitle", res.data.match.name);
              this.$store.dispatch("SetNavLogo", res.data.match.logo);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    changepwd() {
      if (!this.newpwd) {
        return this.$dialog.alert({ title: "提示", message: "密码不能为空" });
      }
      if (this.newpwd !== this.newpwd2) {
        return this.$dialog.alert({
          title: "提示",
          message: "两次输入的新密码不一致",
        });
      }
      if (this.newpwd.length < 6 || this.newpwd.length > 18)
        return this.$dialog.alert({
          title: "提示",
          message: "密码长度要求6-18位",
        });
      if (/\s/.test(this.newpwd)) {
        return this.$dialog.alert({
          title: "提示",
          message: "密码不能包含空格等特殊字符",
        });
      }
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({ Action: "ChangePwd", Data: { newpwd: this.newpwd } })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.$dialog
              .alert({ title: "提示", message: "密码已修改，请重新登录" })
              .then(() => {
                localStorage.clear();
                this.$router.replace({ path: "/login" });
              });
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    logout() {
      localStorage.clear();
      this.$router.replace({ path: "/login" });
    },
  },
};
</script>
<style scoped>
.m-item {
  padding-left: 70px;
}
.content {
  width: 100%;
  padding: 20px 10px 54px;
}
.back {
  cursor: pointer;
}
#overflow_box {
  height: 100vh;
  overflow: scroll;
}
#overflow_box::-webkit-scrollbar {
  display: none;
}
</style>
