<template>
  <div class="contents" :style="customstyle">
    <div v-if="imgUrl" style="width: 100%; height: 100%; position: relative">
      <img
        style="
          width: 100%;
          height: 100%;
          border: 1px dashed #ccc;
          padding: 10px;
          object-fit: cover;
        "
        :src="imgUrl"
        @click="!readonly && $refs._upload_.click()"
      />
      <v-icon
        @click="delImg"
        v-show="close"
        style="position: absolute; top: -12px; right: -12px; cursor: pointer"
      >
        mdi-close-circle-outline
      </v-icon>
    </div>
    <div v-else class="_upload_" @click="!readonly && $refs._upload_.click()">
      <img
        :style="uploadStyle"
        src="https://scdn.xidong360.com/zzty/admin/img/upload.png"
        alt
      />
      <p>点击上传图片</p>
    </div>
    <input
      type="file"
      :accept="accept || 'image/jpeg, image/png'"
      ref="_upload_"
      @change="change"
      id="_upload_"
    />
    <v-dialog v-model="dialog" hide-overlay persistent width="500">
      <v-card :color="type" dark>
        <v-card-text style="padding: 10px; color: #fff">{{ text }}</v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "upload",
  props: [
    "img",
    "imgKey",
    "accept",
    "close",
    "confirm",
    "confirmText",
    "readonly",
    "customStyle",
    "uploadStyle",
  ],
  data() {
    return {
      dialog: false,
      type: "",
      text: "",
      imgurl: null,
    };
  },
  computed: {
    imgUrl() {
      if (this.imgurl === null) return this.img;
      return this.imgurl;
    },
    customstyle() {
      return {
        ...(this.customStyle || {}),
      };
    },
  },
  watch: {
    img(img) {
      console.log("img", img);
      this.imgurl = img;
    },
  },
  methods: {
    showwarntext(text) {
      this.text = text;
      this.type = "warning";
      this.dialog = true;
      setTimeout(() => {
        this.dialog = false;
      }, 1500);
    },
    change(e) {
      let file = e.target.files[0];
      console.log(file);
      if (!file) return;
      this.$refs._upload_.value = null;
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG && !isPNG) {
        return this.showwarntext("上传的图片只能是 JPG 和 PNG 格式!");
      } else if (!isLt2M) {
        return this.showwarntext("上传的图片最大不能超过2MB!");
      }
      let formData = new FormData();
      formData.append("file", file);
      this.$toast.loading({ message: "上传中..." });
      this.$api
        .upload(formData)
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.imgurl = res.data;
            this.$emit("uploadfinish", res.data, this.imgKey);
          } else {
            this.showwarntext(res.msg);
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
          this.showwarntext("上传失败");
        });
    },
    delImg() {
      if (this.confirm) {
        this.$dialog
          .confirm({ title: "提示", message: this.confirmText })
          .then(() => {
            this.$emit("removefinish", this.imgurl);
          })
          .catch(() => {});
      } else {
        this.imgurl = "";
      }
    },
  },
};
</script>
<style lang='less' scoped>
.contents {
  padding: 0;
  min-width: 350px;
  min-height: 200px;
  width: 100%;
  height: 100%;
  ._upload_ {
    width: 100%;
    height: 100%;
    border: 1px dashed #ccc;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 18%;
    }
    p {
      font-size: 12px;
      color: #252525;
    }
  }
  input {
    position: relative;
    display: none;
  }
}
</style>
