import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
import EmptyLayout from '@/layouts/EmptyLayout'
import store from '../store';
import api from '../request'

import WorkBench from '@/views/workbench.vue';
import UserList from '@/views/user/list.vue';
import UserInfo from '@/views/user/info.vue';
import SystemLog from '@/views/systemlog.vue';
import SystemCat from '@/views/systemcat.vue';
import Login from '@/views/login.vue';
import NotFound from '@/views/404.vue';
import MatchList from '@/views/match/list.vue';
import MatchAdd from '@/views/match/add.vue';
import MatchType from '@/views/match/type.vue';
import MatchBasicInfo from '@/views/match/index.vue';
import MatchGroup from '@/views/match/group.vue';
import MatchPlayer from '@/views/match/player.vue';
import VenueList from '@/views/venue/list.vue';
import VenueRecommendList from '@/views/venue/recommendlist.vue';
import VenueAdd from '@/views/venue/add.vue';
import VenueType from '@/views/venue/type.vue';
import VenueBook from '@/views/venue/book.vue';
import VenueBasicInfo from '@/views/venue/index.vue';
import NewsList from '@/views/news/list.vue';
import NewsAdd from '@/views/news/add.vue';
import NewsType from '@/views/news/type.vue';
import BannerList from '@/views/banner/list.vue';
import Circle from "@/views/circle/index.vue";
import Repaire from "@/views/repaire.vue";
import Feedback from "@/views/feedback.vue";

const routes = [
  {
    path: '/',
    redirect: '/workbench'
  },
  //工作台
  {
    path: '/workbench',
    component: WorkBench,
    meta: { layout: 'basic-layout', requireAuth: true, title: "工作台" }
  },
  //赛事管理
  {
    path: '/match',
    component: EmptyLayout,
    redirect: '/match/list',
    children: [
      //赛事列表
      {
        path: 'list',
        component: MatchList,
        meta: { layout: 'basic-layout', requireAuth: true, title: "赛事列表" }
      },
      //创建赛事
      {
        path: 'add',
        component: MatchAdd,
        meta: { layout: 'basic-layout', requireAuth: true, title: "新增赛事" }
      },
      //赛事类型
      {
        path: 'type',
        component: MatchType,
        meta: { layout: 'basic-layout', requireAuth: true, title: "赛事类型" }
      },
    ]
  },
  //场地管理
  {
    path: '/venue',
    component: EmptyLayout,
    redirect: '/venue/list',
    children: [
      //场地列表
      {
        path: 'list',
        component: VenueList,
        meta: { layout: 'basic-layout', requireAuth: true, title: "场地列表" }
      },
      //推荐场地
      {
        path: 'recommend/list',
        component: VenueRecommendList,
        meta: { layout: 'basic-layout', requireAuth: true, title: "推荐场地" }
      },
      //创建场地
      {
        path: 'add',
        component: VenueAdd,
        meta: { layout: 'basic-layout', requireAuth: true, title: "新增场地" }
      },
      //场地类型
      {
        path: 'type',
        component: VenueType,
        meta: { layout: 'basic-layout', requireAuth: true, title: "场地类型" }
      },
      //场地预约
      {
        path: 'book',
        component: VenueBook,
        meta: { layout: 'basic-layout', requireAuth: true, title: "场地预约" }
      },
    ]
  },
  //新闻资讯
  {
    path: '/news',
    component: EmptyLayout,
    redirect: '/news/list',
    children: [
      //新闻列表
      {
        path: 'list',
        component: NewsList,
        meta: { layout: 'basic-layout', requireAuth: true, title: "新闻列表" }
      },
      //发布新闻
      {
        path: 'add',
        component: NewsAdd,
        meta: { layout: 'basic-layout', requireAuth: true, title: "发布新闻" }
      },
      //新闻类型
      // {
      //   path: 'type',
      //   component: NewsType,
      //   meta: { layout: 'basic-layout', requireAuth: true, title: "新闻类型" }
      // },
    ]
  },
  //轮播图管理
  {
    path: '/banner/list/:position',
    component: BannerList,
    meta: { layout: 'basic-layout', requireAuth: true, title: "轮播图管理", }
  },
  //运动圈
  {
    path: '/circle',
    component: Circle,
    meta: { layout: 'basic-layout', requireAuth: true, title: "运动圈" }
  },
  //器材报修
  {
    path: '/equipment/repaire',
    component: Repaire,
    meta: { layout: 'basic-layout', requireAuth: true, title: "器材报修" }
  },
  //意见反馈
  {
    path: '/feedback',
    component: Feedback,
    meta: { layout: 'basic-layout', requireAuth: true, title: "意见反馈" }
  },
  //系统设置
  {
    path: '/user',
    component: EmptyLayout,
    redirect: '/user/info',
    children: [
      //账户管理
      {
        path: 'list',
        component: UserList,
        meta: { layout: 'basic-layout', requireAuth: true, title: "账户管理", }
      },
      //个人中心
      {
        path: 'info',
        component: UserInfo,
        meta: { layout: 'basic-layout', requireAuth: true, title: "个人中心", }
      },
    ]
  },
  {
    path: '/system',
    component: EmptyLayout,
    redirect: '/system/log',
    children: [
      //系统日志
      {
        path: 'log',
        component: SystemLog,
        meta: { layout: 'basic-layout', requireAuth: true, title: "系统日志", }
      },
      // 运动类型
      {
        path: 'cat',
        component: SystemCat,
        meta: { layout: 'basic-layout', requireAuth: true, title: "系统日志", }
      },
    ]
  },
  //赛事
  {
    path: '/matchDetail',
    component: EmptyLayout,
    redirect: '/matchDetail/basicinfo',
    children: [
      //基本信息
      {
        path: 'basicinfo',
        component: MatchBasicInfo,
        meta: { layout: 'basic-layout', requireAuth: true, title: "基本信息", qs: true },
      },
      //报名设置
      {
        path: 'group',
        component: MatchGroup,
        meta: { layout: 'basic-layout', requireAuth: true, title: "报名设置", qs: true },
      },
      //报名数据
      {
        path: 'player',
        component: MatchPlayer,
        meta: { layout: 'basic-layout', requireAuth: true, title: "报名数据", qs: true },
      },
    ]
  },
  //场地
  {
    path: '/venueDetail',
    component: EmptyLayout,
    redirect: '/venueDetail/basicinfo',
    children: [
      //基本信息
      {
        path: 'basicinfo',
        component: VenueBasicInfo,
        meta: { layout: 'basic-layout', requireAuth: true, title: "基本信息", qs: true }
      },
    ]
  },
  //登录
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/home/login',
    component: EmptyLayout,
  },
  //404
  {
    path: '/404',
    component: NotFound,
  },
  {
    path: '*',
    redirect: '/404'
  },
]

const router = new Router({
  mode: 'history',
  routes,
  base: '/admin/'
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) store.dispatch('SetNavTitle', to.meta.title);
  store.dispatch('SetNavLogo', "");
  if (to.meta && to.meta.requireAuth && !localStorage.getItem('zzty.token')) {
    return next({ path: '/login' });
  }
  if (to.path === '/home/login') {
    if (to.query.token) {
      localStorage.setItem('zzty.token', to.query.token);
      api.doAPI({ Action: 'login' }).then((res) => { }).catch((err) => { });
      return next({ path: '/workbench' });
    }
    return next({ path: '/login' });
  }
  if (to.path === '/login' && localStorage.getItem('zzty.token')) return next({ path: '/workbench' });
  next();
});
window._doRouterPush = (path) => router.push({ path });
window._doRouterReplace = (path) => router.replace({ path });

export default router;
