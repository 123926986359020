<template>
  <div class="list_box">
    <div class="top">
      <p>操作记录筛选</p>
      <v-row style="padding-top: 10px">
        <v-col :cols="3">
          <v-select
            v-model="type"
            :items="types"
            item-text="label"
            item-value="value"
            dense
            outlined
            label="操作类型"
            :clearable="type !== ''"
          />
        </v-col>
        <v-col :cols="3">
          <v-menu
            ref="datemenu"
            v-model="datemenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="date"
                label="操作日期"
                v-on="on"
                dense
                outlined
                readonly
                :value="date === '全部' ? '全部' : sdate"
                :clearable="date !== '全部'"
              />
            </template>
            <v-date-picker
              v-model="sdate"
              no-title
              @input="datemenu = false"
              locale="zh"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col :cols="3">
          <v-select
            v-model="accountid"
            :items="allaccounts"
            item-text="name"
            item-value="_id"
            dense
            outlined
            label="操作人"
            :clearable="accountid !== ''"
          />
        </v-col>
        <v-col :cols="3">
          <v-btn
            dark
            style="width: 100%; height: 40px"
            color="teal"
            @click="search"
          >
            查询
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="bottom" ref="list_loading">
      <div class="b_top">
        <span>操作记录</span>
      </div>
      <van-empty v-if="list.length === 0" description="暂无数据"></van-empty>
      <v-data-table
        v-else
        hide-default-footer
        :headers="headers"
        :items="list"
        :items-per-page="limit"
        class="elevation-1"
        disable-sort
        no-data-text="暂无数据"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ item.createdAt | dateTime("YYYY-MM-DD HH:mm") }}
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <span>{{ typename(item.type) }}</span>
        </template>
        <!-- <template v-slot:item.actions="{ item }">
          <v-btn x-small color="success" @click="showdetail(item._id)">详情</v-btn>
        </template>-->
      </v-data-table>
      <div class="text-right pt-2" v-if="length > 1">
        <v-pagination
          v-model="page"
          :page="page"
          :length="length"
          total-visible="10"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "underscore";
export default {
  name: "SystemLog",
  data() {
    return {
      types: [
        { value: "", label: "全部" },
        { value: "insert", label: "新增" },
        { value: "update", label: "修改" },
        { value: "remove", label: "删除" },
        { value: "download", label: "下载" },
        { value: "login", label: "登录" },
      ],
      type: "",
      date: "全部",
      sdate: "",
      datemenu: false,
      accounts: [],
      accountid: "",
      headers: [
        { text: "操作人", value: "accountname", align: "center" },
        { text: "操作类型", value: "type", align: "center" },
        { text: "操作内容", value: "action", align: "center" },
        { text: "操作时间", value: "createdAt", align: "center" },
        // { text: "", value: "actions", align: "center" },
      ],
      list: [],
      page: 1,
      limit: 10,
      length: 0,
      searched: false,
    };
  },
  computed: {
    allaccounts() {
      return [{ _id: "", name: "全部" }, ...this.accounts];
    },
  },
  methods: {
    typename(type) {
      return (_.findWhere(this.types, { value: type }) || { label: "未知" })
        .label;
    },
    search() {
      if (this.page !== 1) this.page = 1;
      else this.getdata();
      this.searched = true;
    },
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({
          Action: "SystemLogs",
          Data: {
            page: this.page,
            limit: this.limit,
            type: this.type,
            date: this.date,
            accountid: this.accountid,
          },
        })
        .then((res) => {
          this.$toast.clear();
          console.log(res);
          if (res.code === 0) {
            this.accounts = res.data.accounts;
            this.list = res.data.list;
            this.length = res.data.length;
            if (this.length > 0 && this.page > this.length) this.page--;
          } else {
            this.$alert(res.msg, { center: true, type: "warning" });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    showdetail(logId) {},
  },
  created() {},
  activated() {
    this.getdata();
  },
  watch: {
    page() {
      this.getdata();
    },
    type(type) {
      if (!type) {
        this.type = "";
        if (this.searched) {
          this.searched = false;
          this.getdata();
        }
      }
    },
    accountid(accountid) {
      if (!accountid) {
        this.accountid = "";
        if (this.searched) {
          this.searched = false;
          this.getdata();
        }
      }
    },
    sdate(sdate) {
      if (sdate) this.date = sdate;
    },
    date(date) {
      if (!date) {
        this.date = "全部";
        this.sdate = "";
        if (this.searched) {
          this.searched = false;
          this.getdata();
        }
      }
    },
  },
};
</script>
<style scoped lang='less'>
.list_box {
  margin: 30px;
  .top {
    padding: 30px;
    margin-bottom: 30px;
    background-color: #fff;
    p {
      font-weight: bold;
    }
  }
  .bottom {
    padding: 30px;
    background-color: #fff;
    .b_top {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid #ddd;
      margin-bottom: 20px;
      span {
        font-weight: bold;
      }
    }
  }
}
</style>
