<template>
  <div :id="id"></div>
</template>

<script>
export default {
  name: "matchmap",
  props: ["id", "location"],
  data() {
    return {
      map: null,
    };
  },
  methods: {
    setcenter({ latitude, longitude }) {
      if (window._map_center_marker_) {
        window._map_center_marker_.setVisible(false);
        delete window._map_center_marker_;
      }
      let center = new qq.maps.LatLng(latitude, longitude);
      this.map.setCenter(center);
      window._map_center_marker_ = new qq.maps.Marker({
        position: center,
        map: this.map,
      });
    },
    init(id) {
      let { address, latitude, longitude } = this.location || {
        address: "",
        latitude: 34.754152,
        longitude: 113.667636,
      };
      latitude = latitude || 34.754152;
      longitude = longitude || 113.667636;
      let center = new qq.maps.LatLng(latitude, longitude);
      let map = new qq.maps.Map(document.getElementById(id), {
        center,
        zoom: 13,
        disableDefaultUI: true,
        scrollwheel: false,
      });
      this.map = map;
      window._map_center_marker_ = new qq.maps.Marker({
        position: center,
        map,
      });
      let infoWin = new qq.maps.InfoWindow({ map });
      let geocoder = new qq.maps.Geocoder({
        complete: (res) => {
          // console.log(res);
          address = res.detail.nearPois[0].name;
          this.$emit("setlocation", { address, latitude, longitude });
        },
      });
      qq.maps.event.addListener(map, "click", (event) => {
        latitude = event.latLng.getLat();
        longitude = event.latLng.getLng();
        console.log(event);
        let lat = new qq.maps.LatLng(latitude, longitude);
        geocoder.getAddress(lat);
        setTimeout(() => {
          infoWin.open();
          infoWin.setContent(
            `<div style="text-align:center;white-space:nowrap;">${address}</div>`
          );
          infoWin.setPosition(event.latLng);
        }, 300);
      });
    },
  },
  mounted() {
    this.init(this.id);
    console.log(this.location);
  },
};
</script>
<style scoped>
div {
  width: 500px;
  height: 300px;
}
</style>
