<template>
  <div class="content">
    <v-row>
      <v-col cols="12" sm="12" md="7">
        <v-text-field
          label="姓名"
          v-model="userinfo.name"
          required
          placeholder="请输入姓名"
        />
      </v-col>
      <v-col cols="12" sm="12" md="7">
        <v-text-field
          label="电话"
          v-model="userinfo.mobile"
          required
          placeholder="请输入电话"
          disabled
        />
      </v-col>
      <v-col cols="12">
        <p style="color: #666; font-size: 16px">头像</p>
        <upload
          id="uploaduserinfo"
          @uploadfinish="setavatar"
          :img="userinfo.avatar"
          :accept="'image/jpeg,image/png'"
        />
      </v-col>
    </v-row>
    <v-btn color="primary" class="mr-4" @click="saveuserinfo">保存</v-btn>
  </div>
</template>
<script>
import upload from "@/components/upload";
export default {
  name: "UserInfo",
  data: () => ({
    userinfo: {
      name: "",
      avatar: "",
    },
  }),
  methods: {
    getuserinfo() {
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({ Action: "UserInfo" })
        .then((res) => {
          // console.log(res);
          this.$toast.clear();
          if (res.code === 0) {
            this.userinfo = res.data.user;
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    saveuserinfo() {
      this.$toast.loading({ message: "" });
      this.$api
        .userSave({ userinfo: this.userinfo })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.getuserinfo();
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    setavatar(avatar) {
      this.userinfo.avatar = avatar;
    },
  },
  components: {
    upload,
  },
  created() {},
  activated() {
    this.getuserinfo();
  },
};
</script>
<style lang='less' scoped>
input[type="file"] {
  position: absolute;
  left: -99999px;
}
#uploaduserinfo {
  width: 200px;
  height: 200px;
}
.content {
  padding: 20px 30px;
  height: 100%;
}
</style>