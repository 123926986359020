import Vue from "vue"
import Vuex from "vuex"
import mutations from "./mutations"
import actions from "./actions"
import _ from "underscore"

Vue.use(Vuex)

const state = {
  initmenus: [
    {
      icon: "mdi-contacts",
      text: "工作台",
      active: false,
      modules: [],
      path: "/workbench",
    },
    {
      icon: "mdi-home",
      text: '场地管理',
      model: false,
      modules: [],
      children: [
        {
          icon: 'mdi-view-list',
          text: '场地列表',
          active: false,
          path: "/venue/list",
        },
        {
          icon: 'mdi-view-list',
          text: '推荐场地',
          active: false,
          path: "/venue/recommend/list",
        },
        {
          icon: 'mdi-plus',
          text: '新建场地',
          active: false,
          path: "/venue/add",
        },
        {
          icon: 'mdi-tag',
          text: '场地类型',
          active: false,
          path: "/venue/type",
        },
        {
          icon: 'mdi-book-variant-multiple',
          text: '场地预约',
          active: false,
          path: "/venue/book",
        },
      ],
    },
    {
      icon: "mdi-ballot-recount",
      text: '赛事管理',
      model: false,
      modules: [],
      children: [
        {
          icon: 'mdi-view-list',
          text: '赛事列表',
          active: false,
          path: "/match/list",
        },
        {
          icon: 'mdi-plus',
          text: '新建赛事',
          active: false,
          path: "/match/add",
        },
        {
          icon: 'mdi-tag',
          text: '赛事类型',
          active: false,
          path: "/match/type",
        },
      ],
    },
    {
      icon: "mdi-newspaper",
      text: '新闻资讯',
      model: false,
      modules: [],
      children: [
        {
          icon: 'mdi-view-list',
          text: '新闻列表',
          active: false,
          path: '/news/list',
        },
        {
          icon: 'mdi-plus',
          text: '发布新闻',
          active: false,
          path: '/news/add',
        },
        // {
        //   icon: 'mdi-tag',
        //   text: '新闻类型',
        //   active: false,
        //   path: "/news/type",
        // },
      ],
    },
    {
      icon: "mdi-view-carousel",
      text: '轮播图管理',
      model: false,
      modules: [],
      children: [
        {
          icon: '',
          text: '首页',
          active: false,
          path: '/banner/list/home',
        },
        {
          icon: '',
          text: '新闻资讯',
          active: false,
          path: '/banner/list/news',
        },
        {
          icon: '',
          text: '体质监测',
          active: false,
          path: '/banner/list/tizhijiance',
        },
        {
          icon: '',
          text: '人才培养-培训学校',
          active: false,
          path: '/banner/list/rencaipeiyang-peixunxuexiao',
        },
        {
          icon: '',
          text: '人才培养-新闻动态',
          active: false,
          path: '/banner/list/rencaipeiyang-xinwendongtai',
        },
        {
          icon: '',
          text: '郑好办',
          active: false,
          path: '/banner/list/onlineclass',
        },
      ],
    },
    {
      icon: "mdi-timeline-text",
      text: "运动圈",
      active: false,
      modules: [],
      path: "/circle",
    },
    {
      icon: "mdi-hammer-wrench",
      text: "器材报修",
      active: false,
      modules: [],
      path: "/equipment/repaire",
    },
    {
      icon: "mdi-pencil-box",
      text: "意见反馈",
      active: false,
      modules: [],
      path: "/feedback",
    },
    {
      icon: "mdi-cog",
      text: "系统设置",
      model: false,
      modules: [],
      children: [
        {
          icon: "mdi-account-cog",
          text: "账户管理",
          active: false,
          path: "/user/list",
        },
        {
          icon: "mdi-account",
          text: "个人中心",
          active: false,
          path: "/user/info",
        },
        {
          icon: "mdi-math-log",
          text: "系统日志",
          active: false,
          path: "/system/log",
        },
        {
          icon: "mdi-tag",
          text: "运动类型",
          active: false,
          path: "/system/cat",
        },
      ],
    },
  ],
  matchmenus: [
    {
      icon: 'mdi-file',
      text: '基本设置',
      path: '/matchDetail/basicinfo',
      active: false,
    },
    {
      icon: "mdi-database-settings",
      text: "报名设置",
      path: "/matchDetail/group",
      active: false,
    },
    {
      icon: "mdi-database-plus",
      text: "报名数据",
      path: "/matchDetail/player",
      active: false,
    },
  ],
  venuemenus: [
    {
      icon: 'mdi-file',
      text: '基本设置',
      active: false,
      path: '/venueDetail/basicinfo',
    },
  ],
  modules: [],
  navTitle: "郑州体育",
  navLogo: "",
}
function getmenukey(_path) {
  let menukeys = ['initmenus', 'matchmenus', 'venuemenus'];
  let menus = [state.initmenus, state.matchmenus, state.venuemenus];
  for (let i = 0; i < menus.length; i++) {
    for (let j = 0; j < menus[i].length; j++) {
      let { path, children } = menus[i][j];
      if (path === _path) return menukeys[i];
      if (children) {
        for (let k = 0; k < children.length; k++) {
          if (children[k].path === _path) return menukeys[i];
        }
      }
    }
  }
}
const getters = {
  getmenus: (state) => (path) => {
    if (path === '/') path = '/workbench';
    let menukey = getmenukey(path);
    let menus = [];
    for (let i = 0; i < state[menukey].length; i++) {
      let menu = { ...state[menukey][i] };
      if (state.modules && state.modules.length > 0) {
        if (_.uniq([...state.modules, ...(menu.modules || [])]).length !== state.modules.length) continue;
      }
      if (menu.children) {
        if (_.findWhere(menu.children, { path })) {
          menu.model = true;
        } else {
          menu.model = false;
        }
        let children = [];
        for (let j = 0; j < menu.children.length; j++) {
          let cmenu = menu.children[j];
          cmenu.active = cmenu.path === path;
          children.push({ ...cmenu });
        }
        menu.children = children;
        if (menu.children.length > 0) menus.push({ ...menu })
      } else {
        menu.active = menu.path === path;
        menus.push({ ...menu });
      }
    }
    console.log('menus', menus);
    return menus;
  }
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
})
