<template>
  <component :is="getlayout"></component>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "App",
  data: () => ({
    current_layout: "empty-layout"
  }),
  computed: {
    getlayout() {
      return this.$route.meta.layout || this.current_layout;
    }
  }
});
</script>