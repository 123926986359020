<template>
  <div class="content" style="background-color: #f7f7f7">
    <v-form
      style="padding: 0 30px; width: 90%; margin: 0 auto 80px; background: #fff"
    >
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <p style="color: #666; font-size: 16px">封面图</p>
            <upload
              id="uploadnewscover"
              @uploadfinish="setcover"
              :img="news.cover"
              :accept="'image/jpeg,image/png'"
            />
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-row justify="center" align="center">
              <v-col cols="12">
                <v-text-field
                  label="标题"
                  placeholder="请输入消息标题"
                  v-model="news.title"
                  required
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="cats"
                  v-model="news.catId"
                  item-text="name"
                  item-value="_id"
                  label="分类"
                  placeholder="选择新闻分类"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="来源"
                  placeholder="请输入新闻来源"
                  v-model="news.author"
                  hint="大河报 人民网等"
                  persistent-hint
                  required
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="摘要"
                  placeholder="请输入新闻摘要"
                  v-model="news.summary"
                  outlined
                  rows="4"
                  row-height="25"
                  shaped
                  dense
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="news.url"
              label="消息图文链接(源于关联公众号)"
              placeholder="https://mp.weixin.qq.com/s/yJvJD3a4rvv9Qjkwm5IrnA"
              dense
            />
          </v-col>
          <v-col cols="12">
            <p style="color: #666; font-size: 16px">详情</p>
            <quill
              @send="setcontent"
              :content="news.content"
              placeholder="请输入新闻正文内容"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <div class="actions">
      <v-btn color="primary" class="mr-4" @click="saveNews">保存</v-btn>
    </div>
  </div>
</template>

<script>
import quill from "@/components/quill";
import upload from "@/components/upload";
import _ from "underscore";
export default {
  name: "NewsAdd",
  data() {
    return {
      news: {
        _id: "",
        title: "",
        author: "",
        catId: "",
        tag: "",
        cover: "",
        summary: "",
        url: "",
        content: "",
      },
      cats: [],
      atags: [],
      atag: "",
    };
  },
  computed: {
    ntags() {
      return _.filter(this.atags, (n) => n.name !== "全部");
    },
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "加载数据" });
      this.$api
        .doAPI({
          Action: "NewsInfo",
          Data: { newsId: this.$route.query.id },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.cats = res.data.cats;
            this.atags = res.data.tags;
            if (res.data.news) this.news = res.data.news;
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    checknews() {
      if (!this.news.title) return "标题不能为空";
      if (!this.news.author) return "来源不能为空";
      if (!this.news.catId) return "分类不能为空";
      if (!this.news.cover) return "封面不能为空";
      // if (!this.news.summary) return "摘要不能为空";
      // if (!this.news.content) return "内容不能为空";
    },
    saveNews() {
      let checkresult = this.checknews();
      if (checkresult) {
        return this.$dialog.alert({ title: "提示", message: checkresult });
      }
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({
          Action: "SaveNews",
          Data: {
            newsinfo: this.news,
          },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.$router.push({ path: "/news/list" });
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    setcover(cover) {
      this.news.cover = cover;
    },
    setcontent(content) {
      this.news.content = content;
    },
    addtag() {
      if (this.atag) {
        if (this.atag === "全部") {
          return this.$dialog.alert({
            title: "提示",
            message: "不允许使用此标签",
          });
        }
        this.$toast.loading({ message: "" });
        this.$api
          .doAPI({
            Action: "AddNewsTag",
            Data: {
              tag: this.atag,
            },
          })
          .then((res) => {
            this.$toast.clear();
            if (res.code === 0) {
              this.atag = "";
              this.atags = res.data.tags;
            } else {
              this.$dialog.alert({ title: "提示", message: res.msg });
            }
          })
          .catch((err) => {
            this.$toast.clear();
            console.error(err);
          });
      } else {
        this.$dialog.alert({ title: "提示", message: "标签不能为空" });
      }
    },
  },
  components: {
    quill,
    upload,
  },
  created() {},
  activated() {
    this.news = {
      _id: "",
      title: "",
      author: "",
      catId: "",
      tag: "",
      cover: "",
      summary: "",
      url: "",
      content: "",
    };
    this.getdata();
  },
};
</script>
<style  scoped>
.content {
  padding: 20px 0 0;
}
#uploadnewscover {
  padding-bottom: 10%;
}
.actions {
  padding-top: 30px;
  position: relative;
  width: 100%;
  height: 100px;
  background: #fcfcfc;
  bottom: 0;
  z-index: 9;
  text-align: center;
}
</style>