import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import waterfall from 'vue-waterfall2'
Vue.use(waterfall)
import BasicLayout from './layouts/BasicLayout'
import EmptyLayout from './layouts/EmptyLayout'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)
Vue.component('basic-layout', BasicLayout);
Vue.component('empty-layout', EmptyLayout);
import { Empty, Toast, Dialog } from 'vant';
Toast.setDefaultOptions('loading', { duration: 0, forbidClick: true, loadingType: "spinner" });
Vue.use(Empty);
Vue.use(Toast);
Vue.use(Dialog);
import utils from './utils';
Vue.prototype.$utils = utils;
import api from './request'
Vue.prototype.$api = api;
import async from 'async';
Vue.prototype.$async = async;
import axios from 'axios'
Vue.prototype.$axios = axios;
import marked from 'marked';
Vue.prototype.$marked = marked;
import moment from 'moment'
moment.locale('zh_cn');
Vue.filter('dateFormat', function (date, pattern = "YYYY-MM-DD") {
  return moment(date).format(pattern)
})
Vue.filter('dateTime', function (date, pattern = "YYYY-MM-DD") {
  return moment(date).format(pattern)
})
Vue.filter('dateTimeFromNow', function (date) {
  return moment(date).fromNow();
})
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
import VueQrcode from '@chenfengyuan/vue-qrcode';
Vue.component(VueQrcode.name, VueQrcode);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
