<template>
  <div class="ccontent">
    <div class="head">
      <div class="head_left">
        <span>运动圈</span>
        <!-- <span class="action">
          <v-icon class="back">mdi-plus-circle-outline</v-icon>添加
        </span> -->
      </div>
    </div>
    <van-empty v-if="circles.length === 0" description="暂无数据" />
    <v-timeline v-else :align-top="true" :dense="true">
      <v-timeline-item
        v-for="item in circles"
        :key="item._id"
        :icon="
          item.images.length > 0 ? 'mdi-message-image' : 'mdi-message-text'
        "
        icon-color
        class="time_item"
        style="left: 0"
      >
        <div style="position: relative; left: -20px">
          <p class="created">
            {{ item.createdAt | dateFormat("YYYY-MM-DD HH:mm") }}
          </p>
          <v-card elevation="2" outlined>
            <!-- <v-card-title
              color="green lighten-1"
              class="headline"
              style="
                border-bottom: 1px solid #ddd;
                padding: 10px;
                display: flex;
                justify-content: space-between;
              "
            >
              <div class="actions">
                <v-btn text color="error">删除 </v-btn>
              </div>
            </v-card-title> -->
            <div style="padding: 10px 0 0 10px">
              <span>
                {{ item.content }}
              </span>
            </div>
            <div v-viewer="{ toolbar: true, url: 'data-source' }">
              <img
                v-for="(image, index) in item.images"
                :key="index"
                class="preview"
                v-lazy="image + '?x-oss-process=style/thumb'"
                :data-source="image"
                style="
                  width: 170px;
                  display: inline-block;
                  margin: 15px 5px;
                  height: 120px;
                  padding: 10px;
                  position: relative;
                  border: 1px dashed #ddd;
                  object-fit: cover;
                "
              />
            </div>
            <v-card-actions>
              <v-btn
                v-if="item.publish === 'offline'"
                outlined
                rounded
                text
                color="success"
                @click="pass(item._id)"
              >
                通过
              </v-btn>
              <v-btn
                outlined
                rounded
                text
                color="error"
                @click="remove(item._id)"
              >
                删除
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import _ from "underscore";
import Vue from "vue";
import { Image, Lazyload, Loading } from "vant";
Vue.use(Image);
Vue.use(Lazyload);
Vue.use(Loading);
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
Vue.use(Viewer);
export default {
  name: "CircleList",
  data() {
    return {
      scrolledToBottom: false,
      circles: [],
    };
  },
  activated() {
    this.getdata();
    this.scroll();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "加载数据" });
      this.$api
        .doAPI({ Action: "CircleList" })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.circles = res.data.circles;
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    pass(circleId) {
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({ Action: "PassCircle", Data: { circleId } })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.getdata();
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    remove(circleId) {
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({ Action: "RemoveCircle", Data: { circleId } })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.getdata();
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    scroll() {
      console.log("scroll");
      window.onscroll = () => {
        console.log("onscroll");
        let bottomOfWindow =
          Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
          ) +
            window.innerHeight ===
          document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          console.log("到达底部");
          this.scrolledToBottom = true; // replace it with your code
        }
      };
    },
  },
};
</script>
<style lang='less' scoped>
.ccontent {
  padding: 20px 20px 54px;
  .head {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    .head_left {
      font-size: 18px;
      span {
        margin-right: 10px;
      }
      .action {
        cursor: pointer;
        color: #3c8dbc;
        .back {
          color: #3c8dbc;
          margin-right: 5px;
        }
      }
    }
  }
  .time_item {
    padding-bottom: 0;
    margin-bottom: 20px;
    p.created {
      font-size: 16px;
      font-weight: 600;
      color: #3c8dbc;
      margin: 10px 0 20px;
    }
  }
}
#tip {
  margin-top: 20px;
  border: 1px solid #ddd;
  padding: 10px;
  border-left: 3px solid #ce4844;
  color: #5e6166;
  p {
    span {
      color: #c7254e;
      font-size: 12px;
    }
  }
  h4 {
    font-size: 18px;
    color: #ce4844;
  }
}
.preview:hover .Imgactions {
  display: block;
  background: rgba(0, 0, 0, 0.5);
}
.preview {
  height: 100px;
  cursor: pointer;
}
.image-list {
  margin: 0;
  padding: 0;
}
.image-list li {
  display: inline-block;
  margin: 0 10px;
  list-style: none;
  position: relative;
}
.image-list li img {
  box-shadow: 0 0 5px #333;
}
.icon-remove {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  background: #f33;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
}
a {
  color: #42b983;
}
.prevArrow,
.nextArrow {
  display: inline-block;
  margin-top: 20%;
  cursor: pointer;
  .v-icon {
    color: #3c8dbc;
  }
}
.prevArrow {
  margin-right: 10px;
}
.nextArrow {
  margin-left: 10px;
}
.v-timeline {
  &::before {
    left: 20px !important;
  }
}
.v-timeline-item__divider {
  justify-content: start !important;
}
img.pswp__img {
  width: 60%;
}
</style>
