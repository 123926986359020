<template>
  <v-app
    id="inspire"
    style="background: url('https://scdn.xidong360.com/zzty/admin/img/bg.png')"
  >
    <v-main>
      <v-container class="fill-height" fluid style="justify-content: center">
        <div class="login">
          <v-row>
            <v-col cols="4" align="center" justify="center">
              <img
                src="https://scdn.xidong360.com/zzty/admin/img/logo.png"
                width="128px"
              />
            </v-col>
          </v-row>
          <v-row align="center" justify="left">
            <v-col cols="4">
              <v-card class="mx-auto">
                <v-toolbar>
                  <v-toolbar-title style="color: rgb(229, 128, 64)">郑州体育平台管理员登录</v-toolbar-title>
                  <v-spacer />
                </v-toolbar>
                <p v-show="isError">{{ errorText }}</p>
                <v-card-text>
                  <v-form>
                    <v-text-field
                      label="账号"
                      :rules="nameRules"
                      name="login"
                      v-model="username"
                      type="text"
                      required
                    />
                    <v-text-field
                      :rules="passRules"
                      id="password"
                      label="密码"
                      name="password"
                      type="password"
                      v-model="password"
                      required
                    />
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    @click="login"
                    style="width: 100%; color: #fff; background-color: #f3a233"
                  >
                    登录
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-main>
    <v-footer padless>
      <v-col class="text-center" cols="12">
        郑州体育 @{{ new Date().getFullYear() }} —
        <strong>
          <a :href="ICP.URL">{{ ICP.CODE }}</a>
        </strong>
      </v-col>
      <v-col class="text-center" cols="12">
        <div style="width: 300px; margin: 0px auto">
          <a
            target="_blank"
            :href="`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${GONGAN.CODE}`"
            style="
              display: inline-block;
              text-decoration: none;
              height: 20px;
              line-height: 20px;
            "
          >
            <img
              src="https://scdn.xidong360.com/gonganbeian.png"
              style="float: left"
            />
            <p
              style="
                float: left;
                height: 20px;
                line-height: 20px;
                margin: 0px 0px 0px 5px;
                color: rgb(147, 147, 147);
              "
            >
              豫公网安备 {{ GONGAN.CODE }}号
            </p>
          </a>
        </div>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      //ICP备案
      ICP: {
        URL: "https://beian.miit.gov.cn/",
        CODE: "豫ICP备16038234号-17",
      },
      //公安备案
      GONGAN: {
        CODE: "41010502004782",
      },
      username: "",
      password: "",
      nameRules: [(v) => !!v || "请输入账号"],
      passRules: [(v) => !!v || "请输入密码"],
      isError: false,
      errorText: "",
    };
  },
  methods: {
    login() {
      const logininfo = { username: this.username, password: this.password };
      this.isError = false;
      this.errorText = "";
      this.$toast.loading({ message: "正在登录" });
      this.$api
        .doAPI({ Action: "Login", Data: { logininfo } })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            localStorage.setItem("zzty.token", res.data.token);
            this.$router.replace({ path: "/" });
          } else {
            this.isError = true;
            this.errorText = res.msg;
            // this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          this.isError = true;
          this.errorText = "登录失败";
          // this.$dialog.alert({ title: "提示", message: "登录失败" });
          console.error(err);
        });
    },
  },
};
</script>
<style lang="less" scoped>
p {
  color: red;
  margin: 10px 15px -10px;
}
.login {
  background: url("https://scdn.xidong360.com/zzty/admin/img/login_bg.png");
  background-size: cover;
  width: 80%;
  height: 80%;
  background-repeat: no-repeat;
  padding: 50px 40px;
}
</style>