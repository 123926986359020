<template>
  <div class="content">
    <!-- <div class="head">
      <div class="head_item">
        <p class="head_title">今日报名</p>
        <p class="head_data" style="color: #67c23a">{{ todaysigncount }}</p>
      </div>
      <div class="head_item">
        <p class="head_title">总报名</p>
        <p class="head_data" style="color: #409eff">{{ totalsigncount }}</p>
      </div>
      <div class="head_item">
        <p class="head_title">报名费</p>
        <p class="head_data" style="color: #e6a23c">{{ totalpay }}</p>
      </div>
    </div> -->
    <div class="main">
      <div class="match_item">
        <div class="match_top">
          <p>赛事LOGO(替换微信小程序码,建议尺寸400*400)</p>
        </div>
        <div class="match_bottom">
          <upload
            :img="match.logo"
            :close="true"
            :confirm="true"
            :confirmText="'删除LOGO'"
            @removefinish="removeLogo"
            @uploadfinish="addLogo"
            style="padding: 0; width: 200px; height: 180px"
            :accept="'image/jpeg,image/png'"
          />
        </div>
      </div>
      <div class="match_item">
        <p>轮播图（微信小程序及H5显示，建议尺寸800*400，最多可上传3张）</p>
        <div style="display: flex">
          <upload
            style="padding: 0; width: 33%; height: 200px; margin-right: 20px"
            :img="match.slider[0]"
            :close="true"
            :confirm="true"
            :confirmText="'删除此轮播图'"
            @removefinish="removeSlider1"
            @uploadfinish="addSlider1"
            :accept="'image/jpeg,image/png'"
          />
          <upload
            style="padding: 0; width: 33%; height: 200px; margin-right: 20px"
            :img="match.slider[1]"
            :close="true"
            :confirm="true"
            :confirmText="'删除此轮播图'"
            @removefinish="removeSlider2"
            @uploadfinish="addSlider2"
            :accept="'image/jpeg,image/png'"
          />
          <upload
            style="padding: 0; width: 33%; height: 200px; margin-right: 20px"
            :img="match.slider[2]"
            :close="true"
            :confirm="true"
            :confirmText="'删除此轮播图'"
            @removefinish="removeSlider3"
            @uploadfinish="addSlider3"
            :accept="'image/jpeg,image/png'"
          />
        </div>
      </div>
      <div class="match_item">
        <p>时间</p>
        <v-row class="items">
          <v-col
            :cols="3"
            style="padding: 0"
            v-for="(item, index) in steps"
            :key="index"
          >
            <div style="color: #999" class="item">
              <div class="point" style="background: #aaa"></div>
              <div class="item-right">
                <div class="item-content">{{ item.content }}</div>
                <div class="time">{{ item.time }}</div>
              </div>
              <div
                v-if="index < steps.length - 1"
                class="line"
                :style="{ border: '2px ' + item.border + ' ' + '#ddd' }"
              ></div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="match_item">
        <div style="display: flex; margin-bottom: 10px">
          <v-icon style="font-size: 28px" small>mdi-map-marker</v-icon>
          <v-row v-if="addressChanged" style="margin: 0; padding: 0">
            <v-col cols="6" style="margin: 0; padding: 0">
              <v-text-field
                v-model="gamelocation.address"
                placeholder="比赛地点"
                dense
                hide-details
              />
            </v-col>
            <v-col
              cols="6"
              style="margin: 0; padding: 0; display: flex; align-items: center"
            >
              <span
                v-if="addressChanged"
                @click="saveAddress"
                style="
                  color: #3c8dbc;
                  margin-left: 5px;
                  cursor: pointer;
                  font-size: 18px;
                "
              >
                保存
              </span>
              <span
                v-if="addressChanged"
                @click="addressChanged = false"
                style="
                  color: #ff5252;
                  margin-left: 5px;
                  cursor: pointer;
                  font-size: 18px;
                "
              >
                放弃
              </span>
            </v-col>
          </v-row>
          <span v-else style="font-size: 18px">地点：{{ match.address }}</span>
        </div>
        <maps
          v-if="fetched"
          id="matchBasicinfoMap"
          @setlocation="setlocation"
          :location="{
            address: match.address,
            latitude: match.latitude,
            longitude: match.longitude,
          }"
        />
      </div>
      <div class="match_item">
        <p>联系方式</p>
        <span>
          <v-icon small>mdi-phone</v-icon>
          联系电话：{{ match.linkphone }}
        </span>
      </div>
      <div class="match_item">
        <p>竞赛规程</p>
        <span v-html="match.guicheng"></span>
      </div>
    </div>
    <v-dialog v-model="showEdit" max-width="800px" persistent>
      <v-card>
        <v-card-title style="display: flex; justify-content: space-between">
          <span class="headline">赛事编辑</span>
          <v-icon @click="showEdit = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="名称"
                  placeholder="请输入赛事名称"
                  v-model="game.name"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="game.catId"
                  item-text="name"
                  item-value="_id"
                  :items="cats"
                  label="赛事分类"
                  placeholder="请选择赛事分类"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="联系电话" v-model="game.linkphone" />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  ref="signupST"
                  v-model="signupST"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="400px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="signupST_"
                      label="报名开始时间"
                      readonly
                      v-on="on"
                      placeholder="请选择报名开始时间"
                    />
                  </template>
                  <van-datetime-picker
                    v-model="game.signupST"
                    type="datetime"
                    title="报名开始时间"
                    @change="setMatchTime('signupST')"
                    @confirm="signupST = false"
                    @cancel="signupST = false"
                    cancel-button-text="关闭"
                  />
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  ref="signupET"
                  v-model="signupET"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="400px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="signupET_"
                      label="报名结束时间"
                      readonly
                      v-on="on"
                      placeholder="请选择报名开始时间"
                    />
                  </template>
                  <van-datetime-picker
                    v-model="game.signupET"
                    type="datetime"
                    title="报名结束时间"
                    @change="setMatchTime('signupET')"
                    @confirm="signupET = false"
                    @cancel="signupET = false"
                    cancel-button-text="关闭"
                  />
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  ref="sdate"
                  v-model="sdate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="400px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="sdate_"
                      label="比赛开始时间"
                      readonly
                      v-on="on"
                      placeholder="请选择比赛开始时间"
                    />
                  </template>
                  <van-datetime-picker
                    v-model="game.sdate"
                    type="datetime"
                    title="比赛开始时间"
                    @change="setMatchTime('sdate')"
                    @confirm="sdate = false"
                    @cancel="sdate = false"
                    cancel-button-text="关闭"
                  />
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  ref="edate"
                  v-model="edate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="400px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="edate_"
                      label="比赛结束时间"
                      readonly
                      v-on="on"
                      placeholder="请选择比赛结束时间"
                    />
                  </template>
                  <van-datetime-picker
                    v-model="game.edate"
                    type="datetime"
                    title="比赛结束时间"
                    @change="setMatchTime('edate')"
                    @confirm="edate = false"
                    @cancel="edate = false"
                    cancel-button-text="关闭"
                  />
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="报名费"
                  placeholder="输入报名费单位元，免费填0"
                  v-model="game.price"
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="第三方小程序"
                  placeholder="第三方小程序"
                  v-model="game.miniapp"
                  dense
                />
              </v-col>
              <v-col cols="12">
                <p>竞赛规程</p>
                <quill
                  @send="setguicheng"
                  :content="game.guicheng"
                  placeholder="请输入竞赛规程"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="margin-right: 24px">
          <v-spacer></v-spacer>
          <v-btn @click="showEdit = false">取消</v-btn>
          <v-btn color="blue darken-1" dark @click="saveMatch">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      style="
        position: fixed;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      "
      @click="editMatch"
      fab
      dark
      large
      color="primary"
    >
      <v-icon dark>mdi-pencil</v-icon>
    </v-btn>
  </div>
</template>

<script>
import _ from "underscore";
import Vue from "vue";
import { Step, Steps, DatetimePicker } from "vant";
Vue.use(Step);
Vue.use(Steps);
Vue.use(DatetimePicker);
import upload from "@/components/upload";
import quill from "@/components/quill";
import maps from "@/components/map";
export default {
  name: "MatchBasicInfo",
  data() {
    return {
      fetched: false,
      cats: [],
      todaysigncount: "-",
      totalsigncount: "-",
      totalpay: "-",
      match: {
        name: "",
        logo: "",
        slider: ["", "", ""],
      },
      game: {},
      gamelocation: {},
      addressChanged: false,
      showEdit: false,
      signupST_: this.$utils.dateTime(new Date(), "YYYY-MM-DD HH:mm"),
      signupET_: this.$utils.dateTime(new Date(), "YYYY-MM-DD HH:mm"),
      sdate_: this.$utils.dateTime(new Date(), "YYYY-MM-DD HH:mm"),
      edate_: this.$utils.dateTime(new Date(), "YYYY-MM-DD HH:mm"),
      signupST: false,
      signupET: false,
      sdate: false,
      edate: false,
      steps: [
        {
          content: "报名开始时间",
          time: "xxxx-xx-xx xx:xx",
          color: "#ddd",
          linecolor: "",
          border: "dashed",
        },
        {
          content: "报名结束时间",
          time: "xxxx-xx-xx xx:xx",
          color: "#ddd",
          linecolor: "",
          border: "dashed",
        },
        {
          content: "比赛开始时间",
          time: "xxxx-xx-xx xx:xx",
          color: "#ddd",
          linecolor: "",
          border: "dashed",
        },
        {
          content: "比赛结束时间",
          time: "xxxx-xx-xx xx:xx",
          color: "#ddd",
          linecolor: "",
          border: "dashed",
        },
      ],
    };
  },
  methods: {
    getallcats() {
      this.$api
        .doAPI({ Action: "AllMatchCats" })
        .then((res) => {
          if (res.code === 0) {
            this.cats = res.data.cats;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    editMatch() {
      this.setGame();
      this.showEdit = true;
    },
    saveMatch() {
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({
          Action: "UpdateMatch",
          Data: { matchId: this.$route.query.id, matchinfo: this.game },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.showEdit = false;
            this.getdata();
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          console.error(err);
          this.$toast.clear();
        });
    },
    setGame() {
      this.game.name = this.match.name;
      this.game.catId = this.match.catId;
      this.game.linkphone = this.match.linkphone;
      this.game.miniapp = this.match.miniapp;
      this.game.price = this.match.price;
      this.game.signupST = new Date(this.match.signupST);
      this.game.signupET = new Date(this.match.signupET);
      this.game.sdate = new Date(this.match.sdate);
      this.game.edate = new Date(this.match.edate);
      this.signupST_ = this.$utils.dateTime(
        this.game.signupST,
        "YYYY-MM-DD HH:mm"
      );
      this.signupET_ = this.$utils.dateTime(
        this.game.signupET,
        "YYYY-MM-DD HH:mm"
      );
      this.sdate_ = this.$utils.dateTime(this.game.sdate, "YYYY-MM-DD HH:mm");
      this.edate_ = this.$utils.dateTime(this.game.edate, "YYYY-MM-DD HH:mm");
      this.game.guicheng = this.match.guicheng;
    },
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({
          Action: "MatchBasicInfo",
          Data: { matchId: this.$route.query.id },
        })
        .then((res) => {
          console.log("matchBasicinfo", res);
          this.$toast.clear();
          if (res.code === 0) {
            // this.todaysigncount = res.data.todaysigncount;
            // this.totalsigncount = res.data.totalsigncount;
            // this.totalpay = res.data.totalpay;
            this.steps = res.data.steps;
            this.match = res.data.match;
            if (res.data.match) {
              this.$store.dispatch("SetNavTitle", res.data.match.name);
              this.$store.dispatch("SetNavLogo", res.data.match.logo);
            }
            this.fetched = true;
            this.addressChanged = false;
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          console.error(err);
          this.$toast.clear();
        });
    },
    setguicheng(guicheng) {
      this.game.guicheng = guicheng;
    },
    setMatchTime(key) {
      this[key + "_"] = this.$utils.dateTime(
        this.game[key],
        "YYYY-MM-DD HH:mm"
      );
    },
    addLogo(url) {
      if (!url) return;
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({
          Action: "AddMatchLogo",
          Data: { matchId: this.$route.query.id, url },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.getdata();
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          console.error(err);
          this.$toast.clear();
        });
    },
    removeLogo(url) {
      if (!url) return;
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({
          Action: "RemoveMatchLogo",
          Data: { matchId: this.$route.query.id },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.getdata();
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          console.error(err);
          this.$toast.clear();
        });
    },
    addSlider1(url) {
      this.addSlider(url, 0, (result) => {
        if (result.code === 0) {
          this.getdata();
        }
      });
    },
    addSlider2(url) {
      this.addSlider(url, 1, (result) => {
        if (result.code === 0) {
          this.getdata();
        }
      });
    },
    addSlider3(url) {
      this.addSlider(url, 2, (result) => {
        if (result.code === 0) {
          this.getdata();
        }
      });
    },
    addSlider(url, index, callback) {
      if (!url) return callback({ code: 1 });
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({
          Action: "AddMatchSlider",
          Data: { matchId: this.$route.query.id, url, index },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            callback({ code: 0 });
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
            callback({ code: 1 });
          }
        })
        .catch((err) => {
          console.error(err);
          this.$toast.clear();
          callback({ code: 1 });
        });
    },
    removeSlider1(url) {
      this.removeSlider(url, (result) => {
        if (result.code === 0) {
          this.getdata();
        }
      });
    },
    removeSlider2(url) {
      this.removeSlider(url, (result) => {
        if (result.code === 0) {
          this.getdata();
        }
      });
    },
    removeSlider3(url) {
      this.removeSlider(url, (result) => {
        if (result.code === 0) {
          this.getdata();
        }
      });
    },
    removeSlider(url, callback) {
      if (!url) return { code: 1 };
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({
          Action: "RemoveMatchSlider",
          Data: { matchId: this.$route.query.id, url },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            callback({ code: 0 });
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
            callback({ code: 1 });
          }
        })
        .catch((err) => {
          console.error(err);
          this.$toast.clear();
          callback({ code: 1 });
        });
    },
    setlocation(location) {
      this.gamelocation = location;
      this.addressChanged = true;
    },
    saveAddress() {
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({
          Action: "SaveMatchAddress",
          Data: {
            matchId: this.$route.query.id,
            ..._.pick(this.gamelocation, ["address", "latitude", "longitude"]),
          },
        })
        .then((res) => {
          console.log(res);
          this.$toast.clear();
          if (res.code === 0) {
            this.getdata();
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          console.error(err);
          this.$toast.clear();
        });
    },
  },
  components: {
    upload,
    quill,
    maps,
  },
  created() {},
  activated() {
    this.getallcats();
    this.getdata();
  },
  mounted() {},
};
</script>
<style lang='less' scoped>
.content {
  padding: 20px 10px 54px;
  .head {
    display: flex;
    border-bottom: 1px solid #eee;
    padding: 10px;
    padding-top: 0;
    .head_item {
      flex: 1;
      border-right: 1px solid #eee;
      p {
        text-align: center;
        color: #999;
        font-size: 18px;
        margin-bottom: 0px;
      }
      p.head_data {
        font-size: 30px;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
  .main {
    margin-top: 10px;
    margin-bottom: 100px;
    .match_item {
      margin-bottom: 10px;
      font-size: 18px;
      color: #444;
      border-bottom: 1px solid #eee;
      padding-bottom: 10px;
      p {
        padding-bottom: 10px;
        margin-bottom: 0;
      }
      span {
        font-size: 14px;
        color: #5e6166;
        display: block;
      }
      .match_top {
        display: flex;
        justify-content: space-between;
        .back {
          margin-right: 5px;
          color: #3c8dbc;
          padding-bottom: -10px;
          font-size: 16px;
        }
        p {
          // &:last-child {
          //   color: #3c8dbc;
          //   font-size: 16px;
          //   cursor: pointer;
          // }
        }
      }
      #upload {
        padding: 0;
        width: 150px;
        height: 140px;
      }
    }
  }
}
#matchBasicinfoMap {
  width: 100%;
  height: 300px;
}
.items {
  margin: 0 30px;
  display: flex;
  .item {
    font-size: 13px;
    color: #666;
    display: flex;
    align-content: center;
    position: relative;
    width: 100%;
    top: 50px;
    left: 20px;
    margin-bottom: 60px;
    .point {
      width: 12px;
      height: 12px;
      background: #e4e7ed;
      border-radius: 50%;
    }
    .line {
      position: absolute;
      left: 12px;
      top: 5px;
      width: 100%;
      height: 2px;
      // background: #e4e7ed;
    }
    .item-right {
      margin-left: -30px;
      position: absolute;
      top: -45px;
      .item-content {
        padding: 0;
      }
    }
  }
}
</style>
