import axios from 'axios'
const instance = axios.create({
  withCredentials: true,
  headers: { 'Content-Type': 'application/json;charset=utf-8' }
});
instance.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('zzty.token')}`;
  return config;
}, error => {
  return Promise.reject(error);
})
//响应拦截器
instance.interceptors.response.use(response => {
  if (response.data.code === 'nopower') {
    if (response.data.data === '/login') localStorage.clear();
    window._doRouterPush(response.data.data);
    return Promise.reject(new Error(response.data.msg));
  }
  return response;
}, error => {
  return Promise.reject(error);
})
// 封装get方法
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    instance.get(url, { params: params }).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err)
    })
  })
}
// 封装post方法
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url, data).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err)
    });
  })
}
export function upload(formData, onUploadProgress = (progressEvent) => {
  console.log(progressEvent.loaded, progressEvent.total);
}) {
  return new Promise((resolve, reject) => {
    instance.post('/api/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem('zzty.token')}`,
      }, onUploadProgress
    }).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err)
    });
  })
}
export function plupload(formData, onUploadProgress = (progressEvent) => {
  console.log(progressEvent.loaded, progressEvent.total);
}) {
  return new Promise((resolve, reject) => {
    instance.post('/api/plupload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem('zzty.token')}`,
      }, onUploadProgress
    }).then(response => {
      resolve(response.data);
    }).catch(err => {
      reject(err)
    });
  })
}
export function doAPI({ Action = "", Data = {} } = { Action: "", Data: {} }) {
  return post('/api', { Action, Data });
}
export default {
  get, post, upload, plupload, doAPI
};