<template>
  <div class="content">
    <van-empty v-if="list.length === 0" description="暂无数据"></van-empty>
    <v-simple-table v-else>
      <thead>
        <tr>
          <th class="text-left">
            排序(可拖动)
            <v-btn v-if="sorted" text small @click="savesort">
              <v-icon color="error">mdi-arrow-up-down</v-icon>保存排序
            </v-btn>
            <v-btn v-else text small color="rgba(0,0,0,0)">
              <v-icon color="rgba(0,0,0,0)">mdi-arrow-up-down</v-icon>保存排序
            </v-btn>
          </th>
          <th class="text-left">类别</th>
          <th class="text-left">名称</th>
          <th class="text-left">联系人</th>
          <th class="text-left">联系电话</th>
          <th class="text-left">操作</th>
        </tr>
      </thead>
      <tbody id="items">
        <tr v-for="item in list" :id="item._id" :key="item._id">
          <td>
            <v-icon>mdi-arrow-up-down</v-icon>
            {{ item.idx + 1 }}
          </td>
          <td>{{ item.cat }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.linkman }}</td>
          <td>{{ item.linkphone }}</td>
          <td>
            <v-btn text x-small color="error" @click="unrecommend(item._id)">
              撤销
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import _ from "underscore";
import Sortable from "sortablejs";
export default {
  name: "VenueRecommendList",
  data() {
    return {
      list: [],
      headers: [
        { text: "排序(可拖动)", value: "index" },
        { text: "类别", value: "cat" },
        { text: "名称", value: "name", align: "center" },
        { text: "联系人", value: "linkman", align: "center" },
        { text: "联系电话", value: "linkphone", align: "center" },
        { text: "操作", value: "action", align: "center", width: 190 },
      ],
      sorted: false,
      sort: [],
      venueIds: [],
    };
  },
  components: {},
  methods: {
    getdata() {
      this.sorted = false;
      this.$toast.loading({ message: "加载数据" });
      this.$api
        .doAPI({
          Action: "VenueRecommendList",
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.list = res.data.venues;
            this.sort = _.pluck(res.data.venues, "_id");
            let that = this;
            setTimeout(() => {
              let el = document.getElementById("items");
              let sortable = Sortable.create(el, {
                onEnd: function (evt) {
                  let oldIndex = evt.oldIndex;
                  let newIndex = evt.newIndex;
                  let to = evt.to;
                  let venues = [];
                  for (let i = 0; i < to.children.length; i++) {
                    venues.push({ _id: to.children[i].id });
                  }
                  that.sorted = that.checksort(venues);
                },
              });
            }, 100);
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    unrecommend(venueId) {
      this.$dialog
        .confirm({ title: "提示", message: "撤销推荐" })
        .then(() => {
          this.$toast.loading({ message: "" });
          this.$api
            .doAPI({
              Action: "UnRecommendVenue",
              Data: { venueId },
            })
            .then((res) => {
              this.$toast.clear();
              if (res.code === 0) {
                this.getdata();
              } else {
                this.$dialog.alert({ title: "提示", message: res.msg });
              }
            })
            .catch((err) => {
              this.$toast.clear();
              console.error(err);
            });
        })
        .catch(() => {});
    },
    checksort(venues) {
      let sort = _.pluck(venues, "_id");
      this.venueIds = sort;
      return sort.join("") !== this.sort.join("");
    },
    savesort() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "保存排序",
        })
        .then(() => {
          this.$toast.loading({ message: "" });
          this.$api
            .doAPI({
              Action: "VenueRecommendSort",
              Data: {
                venueIds: this.venueIds,
              },
            })
            .then((res) => {
              this.$toast.clear();
              if (res.code === 0) {
                this.getdata();
              } else {
                this.$dialog.alert({ title: "提示", message: res.msg });
              }
            })
            .catch((err) => {
              console.error(err);
              this.$toast.clear();
            });
        })
        .catch(() => {});
    },
  },
  created() {},
  activated() {
    this.getdata();
  },
  components: {},
  watch: {},
  mounted() {},
};
</script>
<style  lang='less' scoped>
.content {
  padding: 20px 10px 54px;
}
</style>