<template>
  <div class="content">
    <v-btn color="primary" style="margin-bottom: 20px" @click="showedit">
      <v-icon dark>mdi-plus</v-icon>新增分组
    </v-btn>
    <van-empty v-if="groups.length === 0" description="暂无数据" />
    <v-data-table
      v-else
      :headers="headers"
      :items="groups"
      :items-per-page="groups.length"
      disable-sort
      class="elevation-1"
      hide-default-footer
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-btn text small color="primary" @click="editgroup(item)">编辑</v-btn>
        <v-btn text small color="error" @click="removegroup(item._id)">
          删除
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="showModel" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">{{modelTitle}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="分组名称 *"
                  v-model="group.name"
                  name="group.name"
                  required
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="名额"
                  v-model="group.count"
                  name="group.count"
                  placeholder="留空或者填0表示不限制"
                  required
                />
              </v-col>
            </v-row>
          </v-container>
          <small>* 为必填项</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showModel = false">
            关闭
          </v-btn>
          <v-btn color="primary" @click="savegroup">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from "underscore";
export default {
  name: "MatchGroups",
  data() {
    return {
      headers: [
        { text: "名称", value: "name" },
        { text: "名额", value: "count" },
        { text: "操作", value: "action", align: "center", width: 190 },
      ],
      groups: [],
      group: { _id: "", name: "", count: "" },
      showModel: false,
      modelTitle: "新增分组"
    };
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "加载数据" });
      this.$api
        .doAPI({
          Action: "AllMatchGroups",
          Data: {
            matchId: this.$route.query.id,
          },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.groups = res.data.groups;
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    checkgroup() {
      if (!this.group.name) return "名称不能为空";
    },
    savegroup() {
      let checkresult = this.checkgroup();
      if (checkresult)
        return this.$dialog.alert({ title: "提示", message: checkresult });
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({
          Action: "SaveMatchGroup",
          Data: {
            matchId: this.$route.query.id,
            groupinfo: this.group,
          },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.getdata();
            this.showModel = false;
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    removegroup(groupId) {
      this.$dialog
        .confirm({ title: "提示", message: "删除分组" })
        .then(() => {
          this.$toast.loading({ message: "" });
          this.$api
            .doAPI({
              Action: "RemoveMatchGroup",
              Data: {
                matchId: this.$route.query.id,
                groupId,
              },
            })
            .then((res) => {
              this.$toast.clear();
              if (res.code === 0) {
                this.getdata();
              } else {
                this.$dialog.alert({ title: "提示", message: res.msg });
              }
            })
            .catch((err) => {
              this.$toast.clear();
              console.error(err);
            });
        })
        .catch(() => {});
    },
    showedit() {
      this.setgroup();
      this.modelTitle = "新增分组";
      this.showModel = true;
    },
    editgroup(group) {
      this.setgroup(_.pick(group, _.keys(this.group)));
      this.modelTitle = "编辑分组";
      this.showModel = true;
    },
    setgroup(group) {
      this.group = group || {
        _id: "",
        name: "",
        count: "",
      };
    },
  },
  created() {},
  activated() {
    this.getdata();
  },
  components: {},
  watch: {},
};
</script>
<style scoped>
.v-data-table {
  width: 100%;
}
input[type="file"] {
  position: absolute;
  left: -99999px;
}
#upload {
  width: 350px;
  height: 200px;
}
.content {
  padding: 20px 10px 54px;
}
.item {
  height: 100px;
}
</style>