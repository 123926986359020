import * as types from './types'

export default {
  SetModules({ commit }, data) {
    commit(types.SET_MODULES, data)
  },
  SetNavTitle({ commit }, data) {
    commit(types.SET_NAV_TITLE, data)
  },
  SetNavLogo({ commit }, data) {
    commit(types.SET_NAV_LOGO, data)
  },
  SetGuangChangWu({ commit }, data) {
    commit(types.SET_GUANGCHANGWU, data)
  },
}
