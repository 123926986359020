<template>
  <div class="content">
    <div class="item">
      <v-row>
        <v-col cols="3">
          <v-select
            :items="groups"
            v-model="groupId"
            item-text="name"
            item-value="_id"
            label="分组"
            outlined
            dense
          />
        </v-col>
        <v-col cols="6">
          <span id="search">
            <input
              type="text"
              placeholder="姓名/手机号码/证件号码"
              v-model="searchtext"
            />
            <span @click="search">搜索</span>
          </span>
        </v-col>
      </v-row>
    </div>
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="list"
      :items-per-page="list.length"
      class="elevation-1"
      disable-sort
      no-data-text="暂无数据"
    >
      <template v-slot:top>
        <v-btn color="primary" @click="downloadsign">
          <v-icon class="mr-1" dark size="20"> mdi-cloud-download </v-icon>
          下载报名表
        </v-btn>
      </template>
      <template v-slot:[`header.name`]="{ header }">
        <p v-for="it in header.text" :key="it">{{ it }}</p>
      </template>
      <template v-slot:[`header.sex`]="{ header }">
        <p v-for="it in header.text" :key="it">{{ it }}</p>
      </template>
      <template v-slot:[`header.certificate`]="{ header }">
        <p v-for="it in header.text" :key="it">{{ it }}</p>
      </template>
      <template v-slot:[`item.club`]="{ item }">
        <p>{{ item.club.name }}</p>
      </template>
      <template v-slot:[`item.leader`]="{ item }">
        <p>{{ item.club.leader.name }}</p>
        <p>{{ item.club.leader.mobile }}</p>
        <p>{{ item.club.leader.certificateType }}</p>
        <p>{{ item.club.leader.certificate }}</p>
      </template>
      <template v-slot:[`item.coach`]="{ item }">
        <p>{{ item.club.coach.name }}</p>
        <p>{{ item.club.coach.mobile }}</p>
        <p>{{ item.club.coach.certificateType }}</p>
        <p>{{ item.club.coach.certificate }}</p>
      </template>
      <template v-slot:[`item.team`]="{ item }">
        <p>{{ item.team.name }}</p>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <p>{{ item.name }}</p>
        <p>{{ item.mobile }}</p>
      </template>
      <template v-slot:[`item.sex`]="{ item }">
        <p>{{ item.sex }}</p>
        <p>{{ item.birth }}</p>
      </template>
      <template v-slot:[`item.certificate`]="{ item }">
        <p>{{ item.certificateType }}</p>
        <p>{{ item.certificate }}</p>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn text x-small color="primary" @click="edit(item)">编辑</v-btn>
        <v-btn text x-small color="error" @click="remove(item._id)">删除</v-btn>
      </template>
    </v-data-table>
    <v-pagination
      v-if="length > 1"
      v-model="page"
      :page="page"
      :length="length"
      total-visible="10"
    />
    <v-dialog v-model="showModel" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">编辑信息</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <van-form>
              <van-field v-model="playerinfo.name" label="姓名 *" />
              <van-field
                readonly
                clickable
                :value="playerinfo.sex"
                label="性别 *"
                placeholder="选择性别"
                @click="show('sex')"
              />
              <van-field v-model="playerinfo.mobile" label="手机号码 *" />
              <van-field
                readonly
                clickable
                :value="playerinfo.certificateType"
                label="证件类型 *"
                placeholder="选择证件类型"
                @click="show('certificateType')"
              />
              <van-field v-model="playerinfo.certificate" label="证件号码 *" />
              <van-field
                readonly
                clickable
                :value="playerinfo.birth"
                label="出生日期 *"
                placeholder="选择出生日期"
                @click="show('birth')"
              />
              <van-field v-model="playerinfo.address" label="家庭住址 *" />
              <van-field
                readonly
                clickable
                :value="playerinfo.clothes_size"
                label="衣服尺码 *"
                placeholder="选择衣服尺码"
                @click="show('clothes_size')"
              />
            </van-form>
            <van-popup v-model="showPicker" position="bottom">
              <van-datetime-picker
                v-if="column === 'birth'"
                v-model="currentDate"
                type="date"
                title="选择出生日期"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="onConfirmDate"
                @cancel="showPicker = false"
              />
              <van-picker
                v-else
                show-toolbar
                :columns="columns"
                :default-index="columnIndex"
                @confirm="onConfirm"
                @cancel="showPicker = false"
              />
            </van-popup>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showModel = false">
            关闭
          </v-btn>
          <v-btn color="primary" @click="saveplayer">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import _ from "underscore";
import "@vant/touch-emulator";
import Vue from "vue";
import { Form, Field, Popup, Picker, DatetimePicker } from "vant";
Vue.use(Form);
Vue.use(Field);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(DatetimePicker);
export default {
  name: "PlayerList",
  data() {
    return {
      groupId: "",
      groups: [],
      page: 1,
      limit: 10,
      list: [],
      length: 0,
      headers: [
        { text: "分组", value: "groupname" },
        { text: ["姓名", "手机号码"], value: "name" },
        { text: ["性别", "出生日期"], value: "sex", width: 120 },
        { text: ["证件类型", "证件号码"], value: "certificate" },
        { text: "家庭住址", value: "address" },
        { text: "衣服尺码", value: "clothes_size" },
        { text: "操作", value: "action", align: "center", width: 190 },
      ],
      searchtext: "",
      showModel: false,
      playerinfo: {
        name: "",
        sex: "",
        mobile: "",
        certificateType: "",
        certificate: "",
        birth: "",
        address: "",
        clothes_size: "",
      },
      column: "",
      columns: [],
      columnIndex: -1,
      showPicker: false,
      currentDate: new Date(),
      minDate: new Date(new Date().getFullYear() - 70, 0, 1),
      maxDate: new Date(),
      keynames: {
        name: "姓名",
        sex: "性别",
        mobile: "手机号码",
        certificateType: "证件类型",
        certificate: "证件号码",
        birth: "出生日期",
        address: "家庭住址",
        clothes_size: "衣服尺码",
      },
    };
  },
  methods: {
    search() {
      if (this.page !== 1) this.page = 1;
      else this.getdata();
    },
    getgroups() {
      this.$api
        .doAPI({
          Action: "AllMatchGroups",
          Data: {
            matchId: this.$route.query.id,
          },
        })
        .then((res) => {
          if (res.code === 0) {
            this.groups = [{ _id: "", name: "全部" }, ...res.data.groups];
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getdata() {
      this.$toast.loading({ message: "加载数据" });
      this.$api
        .doAPI({
          Action: "PlayerList",
          Data: {
            page: this.page,
            limit: this.limit,
            matchId: this.$route.query.id,
            groupId: this.groupId,
            searchtext: this.searchtext,
          },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            if (res.data.headers) this.headers = res.data.headers;
            this.list = res.data.list;
            this.length = res.data.length;
            if (this.length > 0 && this.page > this.length) this.page--;
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    edit(player) {
      this.playerinfo = player;
      this.showModel = true;
    },
    saveplayer() {
      let checkresult = this.checkform();
      if (checkresult)
        return this.$dialog.alert({ title: "提示", message: checkresult });
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({
          Action: "SaveMatchPlayer",
          Data: {
            matchId: this.$route.query.id,
            playerinfo: this.playerinfo,
          },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.getdata();
            this.showModel = false;
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    remove(playerId) {
      this.$dialog
        .confirm({ title: "提示", message: "删除报名信息" })
        .then(() => {
          this.$toast.loading({ message: "" });
          this.$api
            .doAPI({
              Action: "RemoveMatchPlayer",
              Data: {
                matchId: this.$route.query.id,
                playerId,
              },
            })
            .then((res) => {
              this.$toast.clear();
              if (res.code === 0) {
                this.getdata();
              } else {
                this.$dialog.alert({ title: "提示", message: res.msg });
              }
            })
            .catch((err) => {
              this.$toast.clear();
              console.error(err);
            });
        })
        .catch(() => {});
    },
    downloadsign() {
      let href = `/api/exportgameplayer?t=${Date.now()}&matchId=${
        this.$route.query.id
      }`;
      Cookies.set(
        "Authorization",
        `Bearer ${localStorage.getItem("zzty.token")}`,
        { expires: new Date(new Date().getTime() + 10 * 60 * 1000) }
      );
      this.$utils.downloadBrowser({ href });
    },
    show(column) {
      this.column = column;
      switch (column) {
        case "sex":
          this.columns = ["男", "女"];
          break;
        case "certificateType":
          this.columns = ["居民身份证"];
          break;
        case "clothes_size":
          this.columns = ["XS", "S", "M", "L", "XL", "XXL", "XXXL", "XXXXL"];
          break;
        case "birth":
          if (this.playerinfo.birth) {
            this.currentDate = new Date(
              this.playerinfo.birth + " 00:00:00.000+08:00"
            );
          }
          break;
      }
      this.columnIndex = this.columns.indexOf(this.playerinfo[this.column]);
      this.showPicker = true;
    },
    onConfirm(value) {
      this.playerinfo[this.column] = value;
      this.showPicker = false;
    },
    onConfirmDate(value) {
      console.log(value);
      this.playerinfo.birth = this.$utils.dateTime(value, "YYYY-MM-DD");
      this.showPicker = false;
    },
    checkform() {
      let keys = _.keys(this.keynames);
      for (let i = 0; i < keys.length; i++) {
        if (!this.playerinfo[keys[i]])
          return `${this.keynames[keys[i]]}不能为空`;
      }
    },
  },
  created() {},
  activated() {
    this.searchtext = "";
    this.getgroups();
    this.getdata();
  },
  components: {},
  watch: {
    page() {
      this.getdata();
    },
    cat() {
      this.tag = "";
    },
  },
  mounted() {},
};
</script>
<style  lang='less' scoped>
p {
  margin-bottom: 0;
}
.content {
  padding: 20px 10px 54px;
  .item {
    .col {
      padding-top: 0;
      padding-bottom: 0;
    }
    #search {
      display: flex;
      position: relative;
      padding-bottom: 12px;
      input {
        display: block;
        border: 1px solid #ddd;
        width: 100%;
        height: 40px;
        padding-left: 10px;
        font-size: 14px;
      }
      span {
        position: absolute;
        width: 60px;
        height: 40px;
        background: #4d95dc;
        color: #fff;
        right: 0;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>