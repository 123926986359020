<template>
  <div class="content" style="background-color: #f7f7f7">
    <v-form
      style="width: 90%; margin: 0 auto 80px; background: #fff; padding: 0 30px"
    >
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <p style="color: #666; font-size: 16px">封面图</p>
            <upload
              id="uploadmatchcover"
              @uploadfinish="setcover"
              :img="match.cover"
              :accept="'image/jpeg,image/png'"
            />
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-row justify="center" align="center">
              <v-col cols="12">
                <v-text-field
                  label="赛事名称"
                  v-model="match.name"
                  required
                  placeholder="赛事名称"
                />
                <v-select
                  v-model="match.catId"
                  item-text="name"
                  item-value="_id"
                  :items="cats"
                  label="赛事类型"
                  placeholder="请选择赛事类型"
                />
                <v-text-field
                  label="报名电话"
                  v-model="match.linkphone"
                  placeholder="报名电话"
                />
                <v-checkbox v-model="match.clubsign" label="俱乐部报名" dense />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="报名费"
              placeholder="输入报名费单位元，免费填0"
              v-model="match.price"
              dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="第三方小程序"
              placeholder="第三方小程序"
              v-model="match.miniapp"
              dense
            />
          </v-col>
          <v-col cols="12">
            <p style="color: #666; font-size: 16px">赛事地点</p>
            <maps
              id="createMatchMap"
              @setlocation="setlocation"
              :location="{
                address: match.address,
                latitude: match.latitude,
                longitude: match.longitude,
              }"
              style="width: 100%; height: 400px"
            />
            <v-text-field
              label="赛事地点"
              v-model="match.address"
              placeholder="赛事地点"
            />
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-menu
              ref="signupST"
              v-model="signupST"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="400px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="signupST_"
                  label="报名开始时间"
                  readonly
                  v-on="on"
                  placeholder="请选择报名开始时间"
                  dense
                />
              </template>
              <van-datetime-picker
                v-model="match.signupST"
                type="datetime"
                title="报名开始时间"
                @change="setMatchTime('signupST')"
                @confirm="signupST = false"
                @cancel="signupST = false"
                cancel-button-text="关闭"
              />
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-menu
              ref="signupET"
              v-model="signupET"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="400px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="signupET_"
                  label="报名结束时间"
                  readonly
                  v-on="on"
                  placeholder="请选择报名开始时间"
                  dense
                />
              </template>
              <van-datetime-picker
                v-model="match.signupET"
                type="datetime"
                title="报名结束时间"
                @change="setMatchTime('signupET')"
                @confirm="signupET = false"
                @cancel="signupET = false"
                cancel-button-text="关闭"
              />
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-menu
              ref="sdate"
              v-model="sdate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="400px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="sdate_"
                  label="比赛开始时间"
                  readonly
                  v-on="on"
                  placeholder="请选择比赛开始时间"
                  dense
                />
              </template>
              <van-datetime-picker
                v-model="match.sdate"
                type="datetime"
                title="比赛开始时间"
                @change="setMatchTime('sdate')"
                @confirm="sdate = false"
                @cancel="sdate = false"
                cancel-button-text="关闭"
              />
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-menu
              ref="edate"
              v-model="edate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="400px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="edate_"
                  label="比赛结束时间"
                  readonly
                  v-on="on"
                  placeholder="请选择比赛结束时间"
                  dense
                />
              </template>
              <van-datetime-picker
                v-model="match.edate"
                type="datetime"
                title="比赛结束时间"
                @change="setMatchTime('edate')"
                @confirm="edate = false"
                @cancel="edate = false"
                cancel-button-text="关闭"
              />
            </v-menu>
          </v-col>
          <v-col cols="12">
            <p style="color: #666; font-size: 16px">竞赛规程</p>
            <quill
              @send="setguicheng"
              :content="match.guicheng"
              placeholder="竞赛规程"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <div class="actions">
      <v-btn
        color="primary"
        x-large
        style="width: 300px"
        class="mr-4"
        @click="saveMatch"
      >
        保存
      </v-btn>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import quill from "@/components/quill";
import upload from "@/components/upload";
import maps from "@/components/map";
import { DatetimePicker } from "vant";
Vue.use(DatetimePicker);
export default {
  name: "MatchAdd",
  data() {
    return {
      cats: [],
      match: {
        name: "",
        catId: "",
        linkphone: "",
        miniapp: "",
        price:0,
        latitude: "",
        longitude: "",
        address: "",
        signupST: new Date(),
        signupET: new Date(),
        sdate: new Date(),
        edate: new Date(),
        guicheng: "",
        cover: "",
        clubsign: false,
      },
      signupST_: this.$utils.dateTime(new Date(), "YYYY-MM-DD HH:mm"),
      signupET_: this.$utils.dateTime(new Date(), "YYYY-MM-DD HH:mm"),
      sdate_: this.$utils.dateTime(new Date(), "YYYY-MM-DD HH:mm"),
      edate_: this.$utils.dateTime(new Date(), "YYYY-MM-DD HH:mm"),
      signupST: false,
      signupET: false,
      sdate: false,
      edate: false,
    };
  },
  methods: {
    getallcats() {
      this.$api
        .doAPI({ Action: "AllMatchCats" })
        .then((res) => {
          if (res.code === 0) {
            this.cats = res.data.cats;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    setlocation(location) {
      this.match.address = location.address;
      this.match.latitude = location.latitude;
      this.match.longitude = location.longitude;
    },
    saveMatch() {
      for (let key in this.match) {
        if (!this.match[key]) {
          if (key == "address") {
            this.$toast({ message: "请点击地图选择地址后再提交数据" });
            return;
          } else if (key == "cover") {
            this.$toast({ message: "请上传封面图后再提交数据" });
            return;
          } else {
            this.$toast({ message: "请填写相关信息后再提交数据" });
          }
        }
      }
      console.log(this.match);
      this.$toast.loading({ message: "" });
      this.$api
        .doAPI({ Action: "CreateMatch", Data: { matchinfo: this.match } })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.$router.push({ path: "/match/list" });
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    setcover(cover) {
      this.match.cover = cover;
    },
    setguicheng(guicheng) {
      this.match.guicheng = guicheng;
    },
    setMatchTime(key) {
      this[key + "_"] = this.$utils.dateTime(
        this.match[key],
        "YYYY-MM-DD HH:mm"
      );
    },
    resetgame() {
      this.match = {
        name: "",
        catId: "",
        linkphone: "",
        miniapp:"",
        price:0,
        latitude: "",
        longitude: "",
        address: "",
        signupST: new Date(),
        signupET: new Date(),
        sdate: new Date(),
        edate: new Date(),
        guicheng: "",
        cover: "",
      };
    },
  },
  components: {
    quill,
    upload,
    maps,
  },
  created() {},
  activated() {
    this.getallcats();
    this.resetgame();
  },
};
</script>
<style  scoped>
.content {
  padding: 20px 0 0;
}
#uploadmatchcover {
  padding-bottom: 10%;
}
.actions {
  position: relative;
  padding-top: 30px;
  width: 100%;
  height: 100px;
  background: #fcfcfc;
  bottom: 0;
  z-index: 9;
  text-align: center;
}
</style>