import { v4 as uuidv4 } from 'uuid';
import { Base64 } from 'js-base64';
import moment from 'moment';
moment.locale('zh_cn');

export const sliceFile = function ({ name, size }) {
  let chunksize = 5 * 1024 * 1024;//文件切割最小5M
  let firstchunksize = 1024;//第一个数据包大小
  let res = [];
  if (size <= chunksize) {
    if (size <= firstchunksize) {
      res.push({ chunk: 0, chunks: 1, name });
    } else {
      let chunks = 2;
      res.push({ chunk: 0, chunks, name, from: 0, to: firstchunksize });
      res.push({ chunk: 1, chunks, name, from: firstchunksize, to: size });
    }
  } else {
    let chunks = (size - firstchunksize) % chunksize == 0 ? Math.floor((size - firstchunksize) / chunksize) : Math.floor((size - firstchunksize) / chunksize) + 1;
    let tchunks = chunks + 1;
    res.push({ chunk: 0, chunks: tchunks, name, from: 0, to: firstchunksize });
    for (let i = 0; i < chunks; i++) {
      res.push({ chunk: i + 1, chunks: tchunks, name, from: (i * chunksize) + firstchunksize, to: (i == chunks - 1) ? size : (i + 1) * chunksize + firstchunksize });
    }
  }
  // console.log(res);
  return res;
}
export const makeUUID = function () {
  return uuidv4().replace(/-/g, '');
}
export const makeVideoOptions = function ({ url, poster }) {
  let playerOptions = {
    //播放速度
    // playbackRates: [0.5, 1.0, 1.5, 2.0],
    //如果true,浏览器准备好时开始回放。
    autoplay: false,
    // 默认情况下将会消除任何音频。
    muted: false,
    // 导致视频一结束就重新开始。
    loop: false,
    // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
    preload: "none",
    language: "zh-CN",
    // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
    aspectRatio: "16:9",
    // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
    fluid: true,
    sources: [],
    //你的封面地址
    poster: "",
    //允许覆盖Video.js无法播放媒体源时显示的默认信息。
    notSupportedMessage: "此视频暂无法播放，请稍后再试",
    controlBar: {
      timeDivider: true,
      durationDisplay: true,
      remainingTimeDisplay: false,
      //全屏按钮
      fullscreenToggle: true
    }
  };
  playerOptions.sources.push({ type: "video/mp4", src: url });
  if (poster) playerOptions.poster = poster;
  return playerOptions;
}
export const dateTime = function (date, formatType) {
  formatType = typeof formatType == 'string' ? formatType : 'YYYY年MM月DD日 HH:mm:ss';
  var res = moment(date).format(formatType);
  return res;
}
export const callPhone = function (phone) {
  var a = document.createElement("a"), //创建a标签
    e = document.createEvent("MouseEvents"); //创建鼠标事件对象
  e.initEvent("click", false, false); //初始化事件对象
  a.href = 'tel:' + phone; //设置下载地址
  a.target = "_blank";
  a.dispatchEvent(e); //给指定的元素，执行事件click事件
}
export const sleep = function (miliseconds = 1500) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, miliseconds);
  });
}
export const toBase64String = function (str) {
  return Base64.encodeURI(str);
}
export const downloadBrowser = ({ name, href }) => {
  var a = document.createElement("a"),
    e = document.createEvent("MouseEvents");
  e.initEvent("click", false, false);
  a.href = href;
  a.target = "_blank";
  if (name) a.download = encodeURIComponent(name);
  a.dispatchEvent(e);
}

export default { sliceFile, makeUUID, makeVideoOptions, dateTime, callPhone, toBase64String, downloadBrowser, sleep };