<template>
  <div class="content">
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="list"
      :items-per-page="list.length"
      class="elevation-1"
      disable-sort
      no-data-text="暂无数据"
    >
      <template v-slot:top>
        <v-btn color="primary" @click="exportvenuebook">
          <v-icon class="mr-1" dark size="20"> mdi-cloud-download </v-icon>
          下载
        </v-btn>
      </template>
    </v-data-table>
    <v-pagination
      v-if="length > 1"
      v-model="page"
      :page="page"
      :length="length"
      total-visible="10"
    />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import _ from "underscore";
export default {
  name: "VenueBookList",
  data() {
    return {
      page: 1,
      limit: 10,
      list: [],
      length: 0,
      headers: [
        { text: "场地", value: "venuename" },
        { text: "姓名", value: "name" },
        { text: "手机号码", value: "mobile" },
        { text: "预约日期", value: "date" },
        { text: "预约时间", value: "time" },
      ],
    };
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "加载数据" });
      this.$api
        .doAPI({
          Action: "VenueBookList",
          Data: {
            page: this.page,
            limit: this.limit,
          },
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 0) {
            this.list = res.data.list;
            this.length = res.data.length;
            if (this.length > 0 && this.page > this.length) this.page--;
          } else {
            this.$dialog.alert({ title: "提示", message: res.msg });
          }
        })
        .catch((err) => {
          this.$toast.clear();
          console.error(err);
        });
    },
    exportvenuebook() {
      let href = `/api/exportvenuebook?t=${Date.now()}`;
      Cookies.set(
        "Authorization",
        `Bearer ${localStorage.getItem("zzty.token")}`,
        { expires: new Date(new Date().getTime() + 10 * 60 * 1000) }
      );
      this.$utils.downloadBrowser({ href });
    },
  },
  created() {},
  activated() {
    this.getdata();
  },
  components: {},
  watch: {
    page() {
      this.getdata();
    },
  },
  mounted() {},
};
</script>
<style  lang='less' scoped>
p {
  margin-bottom: 0;
}
.content {
  padding: 20px 10px 54px;
}
</style>